<template>
  <v-tooltip bottom :disabled="detail.length === 0">
    <template v-slot:activator="{ on, attrs }">
      <span v-if="content" v-bind="attrs" v-on="on">
        {{ content }}
      </span>
      <div v-else v-bind="attrs" v-on="on">
        <slot name="content"> </slot>
      </div>
    </template>
    <span v-html="detail"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    content: String,
    detail: {
      type: String,
      required: true,
    },
  },
};
</script>
