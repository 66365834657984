import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import errorsService from "./errorsService";

export const LOGS_PER_PAGE = 50;

export const COMPANY_SERVICE_ADDED = "CompanyServiceAdded",
  COMPANY_SERVICE_UPDATED = "CompanyServiceUpdated",
  COMPANY_SERVICE_DELETED = "CompanyServiceDeleted",
  ACCESS_ADDED = "AccessAdded",
  ACCESS_UPDATED = "AccessUpdated",
  ACCESS_DELETED = "AccessDeleted",
  MULTI_ASSIGNMENT_REPORT_ADDED = "MultiAssignmentAccessAdded",
  MULTI_ASSIGNMENT_REPORT_UPDATED = "MultiAssignmentAccessUpdated",
  COMPANY_UPDATED = "CompanyUpdated",
  COMPANY_HTTP_MFA_INTEGRATION_ADDED = "CompanyHTTPMFAIntegrationAdded",
  COMPANY_HTTP_MFA_INTEGRATION_DELETED = "CompanyHTTPMFAIntegrationDeleted",
  KEY_ADDED = "KeyAdded",
  KEY_UPDATED = "KeyUpdated",
  KEY_DELETED = "KeyDeleted",
  KEY_TAG_ADDED = "KeyTagAdded",
  KEY_TAG_UPDATED = "KeyTagUpdated",
  KEY_TAG_DELETED = "KeyTagDeleted",
  ENROLLMENT_REGISTERED = "EnrollmentRegistered",
  ENROLLMENT_UPDATED = "EnrollmentUpdated",
  ENROLLMENT_DELETED = "EnrollmentDeleted",
  USER_ADDED = "UserAdded",
  USER_UPDATED = "UserUpdated",
  USER_DELETED = "UserDeleted",
  AUTHORIZATION_STARTED = "AuthorizationStarted",
  AUTHORIZATION_KEY_PROVIDED = "AuthorizationKeyProvided",
  AUTHORIZATION_CONSUMED = "AuthorizationConsumed",
  IMPORT_REPORT_ADDED = "ImportReportAdded",
  IMPORT_REPORT_UPDATED = "ImportReportUpdated",
  GROUP_ADDED = "GroupAdded",
  GROUP_UPDATED = "GroupUpdated",
  GROUP_DELETED = "GroupDeleted";

export default class EventsService {
  constructor() {}

  async getCompanyEvents(limit, end) {
    let endInMilliseconds = parseInt(end / 1000000);
    return await ironchipsdk
      .GETMeCompanyHistoric(limit, endInMilliseconds)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        return [];
      });
  }

  async getCompanyLogs(limit, end) {
    let endInMilliseconds = parseInt(end / 1000000);
    return await ironchipsdk
      .GETMeCompanyEvents(limit, endInMilliseconds)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        return [];
      });
  }
}
