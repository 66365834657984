export default class Breakpoint{
  constructor(name, min, max){
    this.name = name;
    this.min = min;
    this.max = max;
  }

  isInRange(value){
    return value >= this.min && value < this.max;
  }
}
