export const ICONS = {
  //NAVIGATION DRAWER & PAGE TITLES
  GetStarted: "mdi-presentation-play",
  Directory: "mdi-folder",
  Security: "mdi-shield-lock",
  Applications: "mdi-puzzle",
  AddApplication: "mdi-puzzle-plus",
  Licenses: "mdi-credit-card-outline",
  Plugins: "mdi-view-dashboard",
  User: "mdi-account",
  AddUser: "mdi-account-plus",
  UserSync: "mdi-autorenew",
  KeyGroup: "mdi-tag",
  AddKeyGroup: "mdi-tag-plus",
  UserGroup: "mdi-account-multiple",
  AddUserGroup: "mdi-account-multiple-plus",
  MFA: "mdi-fingerprint",
  IDS: "mdi-flag",
  Policies: "mdi-folder-lock",
  Logs: "mdi-clipboard-text",
  CopyToClipboard: "mdi-clipboard-text",
  Link: "mdi-link",
  Globe: "mdi-earth",
  Timeline: "mdi-timeline-alert",
  Metrics: "mdi-speedometer",
  OTP: "mdi-lock-reset",

  //GENERAL PURPOSE
  Create: "mdi-square-edit-outline",
  Read: "mdi-text-box-multiple-outline",
  Update: "mdi-backup-restore",
  Delete: "mdi-delete",
  Star: "mdi-star-four-points",
  Close: "mdi-close",
  Alert: "mdi-alert",
  Download: "mdi-download",
  See: "mdi-eye",
  Upload: "mdi-file-upload-outline",
  RefreshData: "mdi-database-refresh",
  Configure: "mdi-cog",
  Plus: "mdi-plus",
  PlusInCircle: "mdi-plus-circle",
  Minus: "mdi-minus",
  Info: "mdi-information",
  Help: "mdi-help-circle",
  Search: "mdi-magnify",
  CheckCircle: "mdi-check-circle",
  CrossCircle: "mdi-close-circle",
  ExclamationCircle: "mdi-alert-circle",
  RetryCircle: "mdi-rotate-right",
  Devices: "mdi-devices",
  Laptop: "mdi-laptop",
  Cell: "mdi-cellphone",
  Laptop: "mdi-laptop",
  Wifi: "mdi-wifi-marker",
  Application: "mdi-application",
  Key: "mdi-key",
  ClosedPadLock: "mdi-lock",
  OpenPadLock: "mdi-lock-open-variant",
  ChevronRight: "mdi-chevron-right",
  SafeZone: "mdi-map-marker-check",
  Chart: "mdi-finance",
  AccessCondition: "mdi-door-closed-lock",
  Connection: "mdi-connection",
  UploadImage: "mdi-file-image",
  NoDataFound: "mdi-progress-question",
  Clock: "mdi-clock-time-five",
  Scissors: "mdi-content-cut",
  Protection: "mdi-shield-account",
  Unprotected: "mdi-shield-off",
  Company: "mdi-domain",
  CreditCard: "mdi-credit-card-outline",
  BillingInterval: "mdi-cash-sync",
  Cash: "mdi-cash",
  Email: "mdi-email-outline",
  ExternalEmail: "mdi-email-plus",
  Marker: "mdi-map-marker",
  VATNumber: "mdi-numeric",
  Document: "mdi-file-document-outline",
  EnterprisePlan: "mdi-home-city-outline",
  PremiumPlan: "mdi-city-variant-outline",
  Check: "mdi-check",
  Admin: "mdi-shield-crown",
  Contact: "mdi-card-account-mail",
  Authorization: "mdi-message-cancel",
  API: "mdi-api",
  Session: "mdi-account-details",
  NotInvited: "mdi-door-closed-lock",
  //BRANDS
  Windows: "mdi-microsoft-windows",
  Android: "mdi-android",
  iOS: "mdi-apple",
  Facebook: "mdi-facebook",
  LinkedIn: "mdi-linkedin",
  YouTube: "mdi-youtube",

  //ERRORS
  Forbidden: "mdi-folder-lock",
  RateLimit: "mdi-timer-outline",
  LicenseLimit: "mdi-credit-card-remove",
  FolderFile: "mdi-folder-file",

  //ACTIONS
  Import: "mdi-file-import",
  Export: "mdi-file-export",
};

window.icons = ICONS;

export default {
  install(Vue, options) {
    Vue.prototype.$icons = ICONS;
  },
};
