import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import {
  COMPANY_HTTP_MFA_INTEGRATION_ADDED,
  COMPANY_HTTP_MFA_INTEGRATION_DELETED,
} from "./eventsService";
import errorsService from "./errorsService";

const INTEGRATIONS_SERVICE_INITIALIZATION_ERROR = new Error(
  "Error initializing integrations service"
);

export default class IntegrationsService {
  constructor(eventsService, notificationService) {
    this.mfaIntegrations = new Map();
    this.canReadMFA = false;
    this.integrationChangeEvent = new CustomEvent("integrationChanged");

    this._initializeIntegrations();

    this._initializeHTTPMFAIntegrationAddedHandler(
      eventsService,
      notificationService
    );
    this._initializeHTTPMFAIntegrationDeletedHandler(
      eventsService,
      notificationService
    );
  }

  getCompanyMFAs() {
    return Array.from(this.mfaIntegrations.values());
  }

  async addMFA(integration) {
    return await ironchipsdk
      .POSTCompanyHTTPMFA(integration)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteMFA(integrationID) {
    return await ironchipsdk
      .DELETECompanyHTTPMFA(integrationID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async _initializeIntegrations() {
    await ironchipsdk
      .GETCompanyHTTPMFA()
      .then((resp) => {
        resp.forEach((mfaIntegration) => {
          this.mfaIntegrations.set(mfaIntegration.id, {
            id: mfaIntegration.id,
            name: mfaIntegration.name,
            hint: mfaIntegration.hint,
          });
        });
        this.canReadMFA = true;
        window.dispatchEvent(this.integrationChangeEvent);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.canReadMFA = false;
        } else if (error.response.status !== 404) {
          let serviceInitError = new CustomEvent("serviceInitError", {
            detail: INTEGRATIONS_SERVICE_INITIALIZATION_ERROR,
          });
          window.dispatchEvent(serviceInitError);
        }
      });
  }

  _initializeHTTPMFAIntegrationAddedHandler(
    eventsService,
    notificationService
  ) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.COMPANY_HTTP_MFA_INTEGRATION_ADDED,
      function (notification) {
        this._onHTTPMFAIntegrationAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeHTTPMFAIntegrationDeletedHandler(
    eventsService,
    notificationService
  ) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes
        .COMPANY_HTTP_MFA_INTEGRATION_DELETED,
      function (notification) {
        this._onHTTPMFAIntegrationRemoved(notification.data.id);
      }.bind(this),
      null
    );
  }

  _onHTTPMFAIntegrationAdded(integration) {
    this.mfaIntegrations.set(integration.data.id, {
      id: integration.data.id,
      name: integration.data.name,
      hint: integration.data.hint,
    });

    window.dispatchEvent(this.integrationChangeEvent);
  }

  _onHTTPMFAIntegrationRemoved(integrationID) {
    let deletionCompleted = this.mfaIntegrations.delete(integrationID);
    if (!deletionCompleted)
      throw new Error("ERROR: Could not delete the local integration.");

    window.dispatchEvent(this.integrationChangeEvent);
  }
}
