export default class CompanyModel {
  constructor(id, name, language, license, syncAPIKey) {
    this.id = id;
    this.name = name;
    this.language = language;
    this.license = license;
    this.syncAPIKey = syncAPIKey;
  }

  get companyAPIKey() {
    return this.syncAPIKey;
  }

  get companyLanguage() {
    return this.language;
  }
}
