import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import PolicyModel from "../models/policyModel";

export const RESOURCE = {
  ALL: "*",
  GROUPS: "groups",
  COMPANY: "company",
  ACCESSES: "accesses",
  IDS: "ids",
  KEYS: "keys",
  LICENSE: "license",
  SERVICES: "services",
  USERS: "users",
  SYNC: "sync",
  EXPORT: "export",
  MFA: "mfa",
  METRICS: "metrics",
  PERMISSIONS: "permissions",
  TIMELINE: "timeline",
};

export const FILTERTYPE = {
  DEFAULT: "default",
  GROUP: "group",
  KEYTAG: "keytag",
};

export const RESOURCEFILTERS = {
  [RESOURCE.ALL]: [],
  [RESOURCE.GROUPS]: [FILTERTYPE.GROUP],
  [RESOURCE.COMPANY]: [],
  [RESOURCE.ACCESSES]: [FILTERTYPE.GROUP],
  [RESOURCE.IDS]: [],
  [RESOURCE.KEYS]: [FILTERTYPE.GROUP],
  [RESOURCE.LICENSE]: [],
  [RESOURCE.SERVICES]: [],
  [RESOURCE.USERS]: [FILTERTYPE.GROUP],
  [RESOURCE.SYNC]: [],
  [RESOURCE.EXPORT]: [FILTERTYPE.GROUP],
  [RESOURCE.MFA]: [],
  [RESOURCE.METRICS]: [],
  [RESOURCE.PERMISSIONS]: [],
  [RESOURCE.TIMELINE]: [],
};

export const DEFAULT_RESOURCE_FILTER = "*";

export const OPERATION = {
  ALL: "*",
  WRITE: "write",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
};

export const EFFECT = {
  ALLOW: "allow",
  DENY: "deny",
};

export const LOCAL_PERMISSION_EVENTS = {
  PERMISSIONS_CHANGED: "permissionsChanged",
  PERMISSION_REFRESH: "permissionRefresh",
};

export const POLICIES_PER_PAGE = 7;

export default class PoliciesService {
  constructor(notificationService) {
    this.policies = new Map();
    this.lastPolicyCount = 0;
    this.policiesChangedEvent = new CustomEvent(
      LOCAL_PERMISSION_EVENTS.PERMISSIONS_CHANGED
    );
    this.permissionRefreshEvent = new CustomEvent(
      LOCAL_PERMISSION_EVENTS.PERMISSION_REFRESH
    );
    this._initializePolicyAddedHandler(notificationService);
    this._initializePolicyUpdatedHandler(notificationService);
    this._initializePolicyDeletedHandler(notificationService);
  }

  fetchPolicies(limit = 0, skip = 0, filter = "") {
    return ironchipsdk
      .GETMeCompanyPermissions(limit, skip, filter)
      .then(async (response) => {
        this.lastPolicyCount = response.total;
        let temporalPolicies = new Map();
        for (let policyData of response.permissions) {
          const policy = new PolicyModel(policyData);
          temporalPolicies.set(this.getPolicyId(policy), policy);
        }
        this.policies = temporalPolicies;
        window.dispatchEvent(this.policiesChangedEvent);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPolicies() {
    return Array.from(this.policies.values());
  }

  getPolicyCount() {
    return this.lastPolicyCount;
  }

  deletePolicy(policy) {
    let promises = [];
    let errorList = new Map();

    const aggregateError = (currentOperation) => {
      return function (error) {
        errorList.set(currentOperation, error);
      };
    };

    promises = policy.operationEffects.map((operationEffect) => {
      return ironchipsdk
        .DELETEMeCompanyPermission({
          group: policy.group,
          resource: policy.resource,
          filter: policy.filter,
          operation: operationEffect.operation,
          effect: operationEffect.effect,
        })
        .catch(aggregateError(operationEffect.operation));
    });

    return Promise.all(promises).then(() => {
      return errorList;
    });
  }

  getPolicyId(policy) {
    return `${policy.group}-${policy.resource}:${policy.filter}`;
  }

  deletePolicyOperationEffect(policy, policyOperationEffect) {
    const data = {
      group: policy.group,
      resource: policy.resource,
      filter: policy.filter,
      operation: policyOperationEffect.operation,
      effect: policyOperationEffect.effect,
    };
    return ironchipsdk.DELETEMeCompanyPermission(data);
  }

  addPolicyOperationEffect(policy, policyOperationEffect) {
    const data = {
      group: policy.group,
      resource: policy.resource,
      filter: policy.filter,
      operation: policyOperationEffect.operation,
      effect: policyOperationEffect.effect,
    };
    return ironchipsdk.POSTMeCompanyPermission(data);
  }

  updatePolicyOperationEffect(
    policy,
    oldPolicyOperationEffect,
    newPolicyOperationEffect
  ) {
    const oldData = {
      group: policy.group,
      resource: policy.resource,
      filter: policy.filter,
      operation: oldPolicyOperationEffect.operation,
      effect: oldPolicyOperationEffect.effect,
    };
    const newData = {
      group: policy.group,
      resource: policy.resource,
      filter: policy.filter,
      operation: newPolicyOperationEffect.operation,
      effect: newPolicyOperationEffect.effect,
    };
    return ironchipsdk.DELETEMeCompanyPermission(oldData).then(() => {
      return ironchipsdk.POSTMeCompanyPermission(newData);
    });
  }

  _onPolicyAdded(notification) {
    window.dispatchEvent(this.permissionRefreshEvent);
  }

  _onPolicyUpdated(notification) {
    window.dispatchEvent(this.permissionRefreshEvent);
  }

  _onPolicyDeleted(notification) {
    window.dispatchEvent(this.permissionRefreshEvent);
  }

  _initializePolicyAddedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.PERMISSION_ADDED,
      function (notification) {
        this._onPolicyAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializePolicyUpdatedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.PERMISSION_UPDATED,
      function (notification) {
        this._onPolicyUpdated(notification);
      }.bind(this),
      null
    );
  }

  _initializePolicyDeletedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.PERMISSION_DELETED,
      function (notification) {
        this._onPolicyDeleted(notification);
      }.bind(this),
      null
    );
  }
}
