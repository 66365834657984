<template>
  <div>
    <v-footer dark class="corporate-dark-bg">
      <v-container dark>
        <v-row>
          <v-col sm="6" cols="12" class="text-center">
            <img width="160" src="@/assets/icons/logo2.svg" />
            <v-card-text class="pa-0">
              <p class="mb-0 text-large">
                +34 946 03 94 99<br />
                <a
                  class="small-link-white"
                  :href="`https://www.ironchip.com/forms/contact/`">
                  info@ironchip.com
                </a>
                <br />
                Beurko Viejo, 17 <br />
                48901 - Barakaldo (Bizkaia, Spain)
              </p>
            </v-card-text>
          </v-col>
          <v-col sm="3" cols="12" class="text-center pt-5">
            <h2 class="text-uppercase text-white mb-3">Support</h2>
            <ul class="pl-0 list-style-none">
              <li class="mb-1">
                <a
                  class="small-link-white"
                  :href="`https://www.ironchip.com/term_uses/termOfUses/`">
                  Terms of use
                </a>
              </li>
              <li class="mb-1">
                <a
                  class="small-link-white"
                  :href="`https://www.ironchip.com/term_uses/privacyPolicy/`">
                  Privacy Policy
                </a>
              </li>
              <li class="mb-1">
                <a
                  class="small-link-white"
                  :href="`https://www.ironchip.com/term_uses/cookiesPolicy/`">
                  Cookies Policy
                </a>
              </li>
            </ul>
          </v-col>
          <v-col sm="3" cols="12" class="text-center pt-5">
            <h2 class="text-uppercase text-white mb-3">Company</h2>
            <ul class="list-style-none pl-0">
              <li class="mb-1">
                <a class="small-link-white" href="https://blog.ironchip.net/">
                  Blog
                </a>
              </li>
              <li class="mb-1">
                <a
                  class="small-link-white"
                  :href="`https://www.ironchip.com/forms/contact/`">
                  Contact
                </a>
              </li>
            </ul>
            <ul class="justify-center pl-0 d-flex list-style-none">
              <li class="mx-1">
                <a href="https://www.facebook.com/ironchipTelco">
                  <v-icon> mdi-facebook </v-icon>
                </a>
              </li>
              <li class="mx-1">
                <a href="https://www.linkedin.com/company/ironchip/">
                  <v-icon> mdi-linkedin </v-icon>
                </a>
              </li>
              <li class="mx-1">
                <a
                  href="https://www.youtube.com/channel/UCaSTpdwGp7aEBnA44FC-p-g">
                  <v-icon> mdi-youtube </v-icon>
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-text class="text-center pa-0">
          <p class="text-white pt-2">
            Copyright © 2023 All rights reserved by IRON
            <span class="primary--text"> CHIP </span>
          </p>
        </v-card-text>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    selectedEnvironment() {
      if (config.VUE_APP_API_HOST.includes("dev")) return "dev.";
      if (config.VUE_APP_API_HOST.includes("testing")) return "testing.";
      return "";
    },
  },
};
</script>

<style></style>
