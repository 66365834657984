import TimePeriod from "./timePeriodModel";

class PlanLimits {
  constructor(
    options = {
      company_users_limit: 10,
      company_public_keys_limit: 0,
      user_safezones_limit: 0,
    }
  ) {
    this.userLimit = options.company_users_limit;
    this.publicKeyLimit = options.company_public_keys_limit;
    this.safeZoneLimit = options.user_safezones_limit;
  }
}

class LicenseMetrics {
  constructor(
    options = {
      enabled_users: 0,
      public_zones: 0,
    }
  ) {
    this.enabledUsers = options.enabled_users;
    this.publicZones = options.public_zones;
  }
}

export default class LicenseModel {
  constructor(
    options = {
      _id: null,
      company_id: null,
      plan_name: "Basic",
      plan_period: null,
      plan_limits: null,
      price_per_user: 0,
      active_licenses: null,
    }
  ) {
    this.id = options._id;
    this.companyId = options.company_id;
    this.planName = options.plan_name;
    this.planPeriod = new TimePeriod(options.plan_period);
    this.planLimits = new PlanLimits(options.plan_limits);
    this.unitPrice = options.price_per_user;
    this.licenseMetrics = new LicenseMetrics(options.active_licenses);
  }
}
