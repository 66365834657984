import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
/* import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css"; */

let lastError = "";

/* class error {
  constructor(code, message) {
    this.code = code;
    this.message = message;
  }

  show() {
    Swal.fire({
      title: "Error!",
      text: this.message,
      icon: "error",
    });
  }
} */

const errorsService = {
  errors: {},

  errorCodes: {
    invalidSyncFieldData: "200.28.10-200.28.-1",
  },

  onAPIError(error) {
    if (error.response.code === 429) return;
    const errorToPost = {
      error_name: error.response.code,
      error_description: error.response.error,
      metadata: new Map([["traceability_id", error.response.traceabilityId]]),
    };

    ironchipsdk
      .POSTMeCompanyError(errorToPost)
      .then((resp) => {
        if (resp !== 200) console.error("Error Posting new error");
      })
      .catch((err) => {});
  },

  onVueError(error, info) {
    if (lastError === error.toString()) return;
    const errorToPost = {
      error_name: "Vue Error",
      error_description: error.toString(),
      metadata: new Map([["error_info", info]]),
    };

    lastError = errorToPost.error_description;

    ironchipsdk
      .POSTMeCompanyError(errorToPost)
      .then((resp) => {
        if (resp !== 200) console.error("Error Posting new error");
      })
      .catch((err) => {});
  },

  // Sync errors
  onJavaScriptError(error) {
    if (lastError === error) return;
    const errorToPost = {
      error_name: "Javascript Error",
      error_description: error,
    };

    lastError = errorToPost.error_description;

    ironchipsdk
      .POSTMeCompanyError(errorToPost)
      .then((resp) => {
        if (resp !== 200) console.error("Error Posting new error");
      })
      .catch((err) => {});
  },

  // Async errors
  onUnhandledRejectionError(error) {
    if (lastError === error.reason.message) return;
    const errorToPost = {
      error_name: "Unhandled Rejection Error",
      error_description: error.reason.message,
      metadata: new Map([["error_stack", error.reason.stack]]),
    };

    lastError = errorToPost.error_description;

    ironchipsdk
      .POSTMeCompanyError(errorToPost)
      .then((resp) => {
        if (resp !== 200) console.error("Error Posting new error");
      })
      .catch((err) => {});
  },

  errorMessageHandler(errorCode) {
    switch (errorCode) {
      case "100.1.5":
        return "Invalid $wrongFieldName. Please check the provided data and try again.";
      case "100.4.18":
        return "Error during authentication callback process.";
      case "100.4.19":
        return "No active authorizations found for the user.";
      case "100.4.20":
        return "Internal error occurred while retrieving active authorizations.";
      case "100.6.4":
        return "Invalid $wrongFieldName. Please check the provided data and try again.";
      case "100.6.4-100.6.-1":
        return "Error creating service. Please provide a valid URL.";
      case "100.6.9":
        return "Service not found. Please try again shortly.";
      case "100.12.1":
        return "Error parsing petition. Please try again shortly.";
      case "100.14.1":
        return "Error parsing petition. Please try again shortly.";
      case "100.15.1":
        return "User not found. Please try again shortly.";
      case "100.17.5":
        return "Protected User limit reached. Please upgrade your plan to protect more users.";
      case "100.18.4":
        return "User not found. Please try again shortly.";
      case "100.18.5":
        return "User limit reached. Please upgrade your plan to be able to add more users.";
      /* case "100.18.6":
                              return "User safe zone limit reached. Please upgrade your plan to be able to add more safe zones."; */
      case "100.18.7":
        return "Public key limit reached. Please upgrade your plan to be able to add more public keys.";
      case "100.24.1":
        return "Invalid billing details. Please check the provided details and try again.";
      case "200.1.22":
        return "The provided key is associated with an invalid state.";
      case "200.1.23":
        return "The submitted key is invalid.";
      case "200.1.24":
        return "The authorization already has all the required keys.";
      case "200.3.1":
        return "User not found. Please try again shortly.";
      case "200.3.8":
        return "The selected device platforms and the provided keys don't match. Please select a matching set and try again.";
      case "200.3.9":
        return "The provided keys don't match the required key types for the selected service. Please select a matching set and try again.";
      case "200.3.13":
        return "There was an error finding the multiassignment report. Please try again shortly.";
      case "200.3.15":
        return "The selected device platforms and the provided keys don't match. Please select a matching set and try again.";
      case "200.3.16":
        return "The selected company keys are not valid. Please select a valid set and try again.";
      case "200.3.17":
        return "There is already a massive assignment in progress. Please wait for its completion and try again.";
      case "200.3.20":
        return "The external username template you have chosen, when applied to the user or groups selected, generates more than one user with the same external username for this service. \nIt is not possible to have more than one user with the same external username for a service.";
      case "200.7.5":
        return "Error adding new $serviceType type service. Please check your provided data and try again.";
      case "200.7.12":
        return "The provided service name is already in use. Please choose a different one and try again.";
      case "200.9.6":
        return "Key not found. Please try again shortly.";
      case "200.9.8":
        return "Key belongs to another user. Please try again shortly.";
      case "200.9.12":
        return "You are trying to delete a key that is still in use in a service or by a user, check it and try again to delete it.";
      case "200.9.13":
        return "You are trying to delete a key that is being used in a service. Remove the key from the service and try again to delete it.";
      case "200.9.14":
        return "You are trying to delete your last device. This operation has been cancelled for security reasons.";
      case "200.11.3":
        return "User not found. Please try again shortly.";
      case "200.14.5":
        return "You are trying to delete an integration that is still in use in a service, check your services and try again.";
      case "200.23.10":
        return "This user does not have a valid recovery configuration. Please configure their recovery options and try again.";
      case "200.23.8":
        return "User's recovery device is not configured. Please configure one and try again.";
      case "200.28.14":
        return "User can not be manipulated.";
      case "200.34.10":
        return "$keygroup is still in use, and cannot be deleted. Please make sure to remove it from any accesses it is related to and try again.";
      case "200.34.13":
        return "Invalid certificate file. Please make sure you provide a valid certificate file and try again.";
      case "200.34.14":
        return "The user cannot be added to the group. Attempt to create a duplicated access external username.";
      case "200.34.15":
        return "Incorrect TLS configuration. Please check your settings and try again.";
      case "200.34.16":
        return "Could not resolve host. Please provide a valid LDAP server address and try again.";
      case "200.34.17":
        return "The submitted credentials are not valid for $username. Please check your credentials and try again.";
      case "200.3.2":
        return "The access cannot be added to the service. Attempt to create a duplicated access external username.";
      case "200.35.6":
        return "The user is already part of the group.";
      case "100.17.7":
        return "Public keys license limit reached.";
      case "200.9.16":
        return "Unable to delete the last key.";
      case "200.28.11":
        return "The user license limit has been reached.";
      case "300.1.3":
        return "The user cannot be added to the group. Attempt to create a duplicated access external username.";
      case "300.21.11":
        return "Some users are duplicated.";
      case "200.28.10":
        return "The document had a bad format.";
      case "200.36.2":
        return "You don't have enough permissions to manage the selected resource.";
      case "200.37.3":
        return "You don't have enough permissions to manage the selected resource.";
      case "429":
        return "Rate limit reached. You are operating too fast - please contact support or give the system some time to cool down.";
      case "402":
        return "License limit reached. Please upgrade your license to perform this operation.";
      default:
        return "There was an unexpected error. Please try again shortly.";
    }
  },
};

export default errorsService;
