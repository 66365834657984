import DeviceOwnershipKeyData from "./deviceOwnershipKeyDataModel";
import safeZoneKeyData from "./safeZoneKeyDataModel";
import OTPKeyData from "./OTPKeyDataModel";
import { ICONS } from "../utils/icons";

export const SAFEZONE_DATA_TYPE_ANDROID = "EM",
  SAFEZONE_DATA_TYPE_IOS = "GEO",
  SAFEZONE_DATA_TYPE_WIFI = "WIFI",
  DEVICE_OWNERSHIP_TYPE = "DO",
  SAFEZONE_TYPE = "SZ",
  OTP_TYPE = "OTP",
  PLATFORM_ANDROID = "android",
  PLATFORM_IOS = "ios",
  PLATFORM_DESKTOP = "desktop";

export default class KeyModel {
  constructor(id, type, shared, userId, companyId, data, assigned = undefined) {
    this.id = id;
    this.type = type;
    this.shared = shared;
    this.userId = userId;
    this.companyId = companyId;
    if (type === DEVICE_OWNERSHIP_TYPE) {
      this.data = new DeviceOwnershipKeyData(data.name, data.platform);
    } else if (type === SAFEZONE_TYPE) {
      this.data = new safeZoneKeyData(data.name, data.type);
    } else if (type === OTP_TYPE) {
      this.data = new OTPKeyData(data);
    }
    this.assigned = assigned;
  }

  getKeyIcon() {
    switch (this.type) {
      case DEVICE_OWNERSHIP_TYPE:
        return this.data.platform === PLATFORM_DESKTOP
          ? ICONS.Laptop
          : ICONS.Cell;
      case SAFEZONE_TYPE:
        return ICONS.Marker;
      case OTP_TYPE:
        return ICONS.OTP;
      default:
        return;
    }
  }

  getKeyType() {
    switch (this.type) {
      case DEVICE_OWNERSHIP_TYPE:
        return "Personal device";
      case SAFEZONE_TYPE:
        return "Safe zone";
      case OTP_TYPE:
        return "OTP";
      default:
        return "Undefined";
    }
  }

  getKeyPlatform() {
    var platformType =
      this.type == DEVICE_OWNERSHIP_TYPE ? this.data.platform : this.data.type;
    switch (platformType) {
      case SAFEZONE_DATA_TYPE_ANDROID:
        return "Android";
      case SAFEZONE_DATA_TYPE_IOS:
        return "IOS";
      case SAFEZONE_DATA_TYPE_WIFI:
        return "Desktop";
      case PLATFORM_ANDROID:
        return "Android";
      case PLATFORM_IOS:
        return "IOS";
      case PLATFORM_DESKTOP:
        return "Desktop";
      default:
        return platformType;
    }
  }

  getKeyPlatformIcon() {
    var platformType =
      this.type == DEVICE_OWNERSHIP_TYPE ? this.data.platform : this.data.type;
    switch (platformType) {
      case SAFEZONE_DATA_TYPE_ANDROID:
        return ICONS.Android;
      case SAFEZONE_DATA_TYPE_IOS:
        return ICONS.iOS;
      case SAFEZONE_DATA_TYPE_WIFI:
        return ICONS.Laptop;
      case PLATFORM_ANDROID:
        return ICONS.Android;
      case PLATFORM_IOS:
        return ICONS.iOS;
      case PLATFORM_DESKTOP:
        return ICONS.Laptop;
      default:
        return ICONS.Cell;
    }
  }

  getKeyName() {
    switch (this.type) {
      case DEVICE_OWNERSHIP_TYPE:
        return this.data.name;
      case SAFEZONE_TYPE:
        return this.data.name;
      case OTP_TYPE:
        return `${this.data.issuer}:${this.data.account}`;
      default:
        return "Undefined";
    }
  }
}
