import GroupModel from "@/models/groupModel";
import {
  ironchipsdk,
  GROUP_PERSONAL_TYPE,
  GROUP_COLLECTIVE_TYPE,
  ALL_GROUP_NAME,
  ADMIN_GROUP_NAME,
} from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import errorsService from "./errorsService";

export const USERS_PER_PAGE_GROUPS = 5,
  GROUP_ADD_EVENT = "groupsAdded",
  GROUP_DELETE_EVENT = "groupsDeleted",
  GROUP_UPDATE_EVENT = "groupsUpdated",
  GROUP_CHANGE_EVENT = "groupsChanged";

export const INDIVIDUAL_TYPE = GROUP_PERSONAL_TYPE,
  COLLECTIVE_TYPE = GROUP_COLLECTIVE_TYPE,
  ALL_NAME = ALL_GROUP_NAME,
  ADMIN_NAME = ADMIN_GROUP_NAME;

export default class GroupsService {
  constructor(eventsService, acessesService, notificationService) {
    this.adminGroup = undefined;
    this.accessesService = acessesService;
    this.groups = new Map();
    this.deletedGroupIds = new Map();
    this.groupsAddedEvent = new CustomEvent(GROUP_ADD_EVENT);
    this.groupsDeletedEvent = new CustomEvent(GROUP_DELETE_EVENT);
    this.groupsUpdatedEvent = new CustomEvent(GROUP_UPDATE_EVENT);
    this.groupsChangedEvent = new CustomEvent(GROUP_CHANGE_EVENT);

    this._initializeGroupsAddedHandler(eventsService, notificationService);
    this._initializeGroupsUpdatedHandler(eventsService, notificationService);
    this._initializeGroupsDeletedHandler(eventsService, notificationService);
  }

  async addGroup(groupName, groupType) {
    const group = {
      name: groupName,
      type: groupType,
    };
    return await ironchipsdk
      .POSTMeCompanyGroups(group)
      .then((resp) => {
        window.dispatchEvent(this.groupsChangedEvent);
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteGroup(groupId) {
    return await ironchipsdk
      .DELETEMeCompanyGroupByID(groupId)
      .then((resp) => {
        window.dispatchEvent(this.groupsChangedEvent);
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async getGroupById(groupId) {
    return await ironchipsdk
      .GETMeCompanyGroupByID(groupId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        errorsService.onAPIError(err);
        throw err;
      });
  }

  getGroupChildren(included, groupId, limit = "", skip = "", filter = "") {
    return ironchipsdk
      .GETMeCompanyGroupChildren(included, groupId, limit, skip, filter)
      .then((groups) => {
        return groups;
      })
      .catch((error) => {
        if ((error.response.status = "404")) {
          console.error("No group children found");
        }
        return [];
      });
  }

  getGroupChildrenCount(groupId, included, filter) {
    return ironchipsdk
      .GETMeCompanyGroupChildrenCount(groupId, included, filter)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return 0;
      });
  }

  async fetchGroups(type, userId, limit = "", skip = "", filter = "") {
    return await ironchipsdk
      .GETMeCompanyGroups(type, userId, limit, skip, filter)
      .then((groups) => {
        return groups;
      })
      .catch((error) => {
        if ((error.response.status = "404")) {
          console.error("No matching groups found");
        }
        return [];
      });
  }
  async fetchGroupsCount(type = "", filter = "") {
    return await ironchipsdk
      .GETMeCompanyGroupsCount(type, filter)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async userGroupsInfo(userId) {
    return await ironchipsdk
      .GETMeCompanyGroups("", userId, "", "", "")
      .then((groups) => {
        let found = false;
        let personalGroupId = undefined;
        for (
          let index = 0;
          index < groups.length && (!found || personalGroupId == undefined);
          index++
        ) {
          if (groups[index].name == ADMIN_GROUP_NAME) {
            found = true;
          } else if (groups[index].type != COLLECTIVE_TYPE) {
            personalGroupId = groups[index].id;
          }
        }
        return { isAdministrator: found, personalGroupId: personalGroupId };
      })
      .catch(() => {
        return false;
      });
  }

  async getCompanyAdminGroup(userId) {
    if (this.adminGroup != undefined) return this.adminGroup;

    return await ironchipsdk
      .GETMeCompanyGroups("", userId, "", "", "")
      .then((groups) => {
        for (let group in groups) {
          if (groups[group].name == ADMIN_GROUP_NAME) {
            this.adminGroup = groups[group];
            return this.adminGroup;
          }
        }
      })
      .catch(() => {
        return null;
      });
  }

  async updateGroup(groupID, data) {
    await ironchipsdk
      .PUTMeCompanyGroupUpdateByID(groupID, data)
      .then(() => {
        window.dispatchEvent(this.groupsChangedEvent);
      })
      .catch((error) => {
        throw error;
      });
  }

  _initializeGroupsAddedHandler(eventsService, notificationService) {
    //TODO IMPLEMENT REAL HANDLER
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.GROUP_ADDED,
      function (notification) {}.bind(this),
      null
    );
  }

  _initializeGroupsUpdatedHandler(eventsService, notificationService) {
    //TODO IMPLEMENT REAL HANDLER
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.GROUP_UPDATED,
      function (notification) {}.bind(this),
      null
    );
  }

  _initializeGroupsDeletedHandler(eventsService, notificationService) {
    //TODO IMPLEMENT REAL HANDLER
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.GROUP_DELETED,
      function (notification) {}.bind(this),
      null
    );
  }

  _onGroupAdded(group) {
    window.dispatchEvent(this.groupsAddedEvent);
    window.dispatchEvent(this.groupsChangedEvent);
  }

  async _onGroupsUpdated(key) {
    window.dispatchEvent(this.groupsUpdatedEvent);
    window.dispatchEvent(this.groupsChangedEvent);
  }

  async _onGroupsDeleted(key) {
    window.dispatchEvent(this.groupsDeletedEvent);
    window.dispatchEvent(this.groupsChangedEvent);
  }
}
