import { EFFECT, DEFAULT_RESOURCE_FILTER } from "@/services/policiesService";

const DEFAULT_OPTIONS = {
  filter: null,
  filter_name: null,
  group: null,
  group_name: null,
  operation_effects: [],
  resource: null,
};

export default class PolicyModel {
  constructor(options = DEFAULT_OPTIONS) {
    this.filter = options.filter || "*";
    this.filterName = options.filter_name || "All";
    this.group = options.group;
    this.groupName = options.group_name;
    this.operationEffects = options.operation_effects;
    this.resource = options.resource;
  }

  getOperation(operation) {
    return (
      this.operationEffects.filter((operationEffect) => {
        return operationEffect.operation === operation;
      })[0] || null
    );
  }

  getOperationsByEffect(effect) {
    return this.operationEffects.filter(
      (operation) => operation.effect === effect
    );
  }

  getOperationEffect(operation) {
    return (
      this.operationEffects.filter((operationEffect) => {
        return operationEffect.operation === operation;
      })[0]?.effect || null
    );
  }

  //TODO: MANAGE THESE AS A MAP
  addOperationEffect(operationEffect) {
    this.removeOperationEffect(operationEffect.operation);
    this.operationEffects = [...this.operationEffects, operationEffect];
  }

  removeOperationEffect(operation) {
    this.operationEffects = this.operationEffects.filter((operationEffect) => {
      return operationEffect.operation !== operation;
    });
  }

  translatePoliciesToString(policyArray) {
    const newEntry = (effect, operation, separator) =>
      `<span style="color: ${getEntryColor(effect)}"><b>${operation.replace(
        DEFAULT_RESOURCE_FILTER,
        "perform any operations on"
      )}</b></span>${separator}`;

    const getEntryColor = (effect) =>
      effect === EFFECT.ALLOW ? "green" : "red";

    const transform = policyArray.reduce((acc, cur, index) => {
      let separator;
      if (index < policyArray.length - 2) {
        separator = ", ";
      } else if (index === policyArray.length - 1) {
        separator = "";
      } else {
        separator = " or ";
      }
      return (acc += newEntry(cur.effect, cur.operation, separator));
    }, "");
    return transform;
  }

  getClonedInstance() {
    return new PolicyModel({
      filter: this.filter,
      filter_name: this.filterName,
      group: this.group,
      group_name: this.groupName,
      operation_effects: this.operationEffects,
      resource: this.resource,
    });
  }
}
