import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import ServiceModel, {
  SERVICE_TYPE_MFA,
  SERVICE_TYPE_OIDC,
  SERVICE_TYPE_APIKEY,
  SERVICE_TYPE_SAML,
} from "../models/serviceModel";
import {
  COMPANY_SERVICE_ADDED,
  COMPANY_SERVICE_DELETED,
  COMPANY_SERVICE_UPDATED,
} from "./eventsService";
import errorsService from "./errorsService";

const SERVICES_SERVICE_INITIALIZATION_ERROR = new Error(
  "Error initializing services service"
);

export const SERVICES_PER_PAGE = 7,
  SERVICE_USERS_PER_PAGES = 5;

export default class ServicesService {
  constructor(eventsService, notificationService) {
    this.services = new Map();
    this.canReadServices = false;

    this._initializeServices();

    this.serviceChangeEvent = new CustomEvent("serviceChanged");
    this._initializeServiceAddedHandler(eventsService, notificationService);
    this._initializeServiceUpdatedHandler(eventsService, notificationService);
    this._initializeServiceDeletedHandler(eventsService, notificationService);
  }

  getServices() {
    return Array.from(this.services.values());
  }

  getService(serviceID) {
    return this.services.get(serviceID);
  }

  getServiceName(serviceID) {
    return this.services.get(serviceID).name;
  }

  async addService(service) {
    const serviceAdded = {
      external_name: service.external_name,
      integration: service.integration,
      minimum_key_types: service.minimum_key_types,
      name: service.name,
      type: service.type,
      /* image_url: "", */ //OJO
    };
    return await ironchipsdk
      .POSTMeCompanyServices(serviceAdded)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteService(serviceID) {
    return await ironchipsdk
      .DELETEMeCompanyServiceByID(serviceID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async updateApplicationName(serviceID, newApplicationAlias) {
    return await ironchipsdk
      .PUTMeCompanyServiceAliasByID(serviceID, newApplicationAlias)
      .then((resp) => {
        this._initializeServices();
        return true;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        /* throw err; */
        return false;
      });
  }

  async updateApplicationLogo(serviceID, newApplicationImage) {
    return await ironchipsdk
      .PUTMeCompanyServiceImageByID(serviceID, newApplicationImage)
      .then((resp) => {
        this._initializeServices();
        return true;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        /* throw err; */
        return false;
      });
  }

  async _initializeServices() {
    await ironchipsdk
      .GETMeCompanyServices()
      .then((services) => {
        let temporalServices = new Map();
        services.forEach((service) => {
          temporalServices.set(
            service.id,
            new ServiceModel(
              service.id,
              service.name,
              service.external_name,
              service.company_id,
              service.minimum_key_types,
              service.type,
              service.integration,
              service.data,
              service.logo_url
            )
          );
        });
        this.services = temporalServices;
        this.canReadServices = true;
        window.dispatchEvent(this.serviceChangeEvent);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.canReadServices = false;
        } else {
          let serviceInitError = new CustomEvent("serviceInitError", {
            detail: SERVICES_SERVICE_INITIALIZATION_ERROR,
          });
          window.dispatchEvent(serviceInitError);
        }
      });
  }

  _initializeServiceAddedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.COMPANY_SERVICE_ADDED,
      function (notification) {
        this._onServiceAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeServiceUpdatedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.COMPANY_SERVICE_UPDATED,
      function (notification) {
        this._onServiceRemoved(notification);
        this._onServiceAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeServiceDeletedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.COMPANY_SERVICE_DELETED,
      function (notification) {
        this._onServiceRemoved(notification);
      }.bind(this),
      null
    );
  }

  _onServiceAdded(service) {
    this._initializeServices();

    window.dispatchEvent(
      new CustomEvent("serviceChanged", {
        detail: { name: service.data.name, id: service.data.id },
      })
    );
  }

  _onServiceRemoved(service) {
    let deletionCompleted = this.services.delete(service.data.id);
    if (!deletionCompleted)
      throw new Error("ERROR: Could not delete the local service.");

    window.dispatchEvent(this.serviceChangeEvent);
  }
}
