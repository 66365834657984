<template>
  <v-card elevation="160">
    <v-card-title class="pb-1 d-flex justify-space-between">
      <div class="d-flex">
        <v-icon size="30" class="" style="color: red">
          {{ this.$icons.ExclamationCircle }}
        </v-icon>
        <span class="ml-2">
          {{ title }}
        </span>
      </div>
      <v-icon size="20" class="close-icon" @click="emitContinue" right>
        {{ $icons.Close }}
      </v-icon>
    </v-card-title>
    <v-divider class="mx-6" />
    <v-card-text class="mt-2">
      <div class="text-main mt-3">
        {{ subtitle }}
        <span v-if="subtitleHtml != ''" v-html="subtitleHtml"></span>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex px-6 pb-4 justify-end">
      <StandardButton
        :buttonText="buttonName"
        @onClick="emitContinue"
        small
        :width="100"
        classname="primary-btn" />
    </v-card-actions>
  </v-card>
</template>

<script>
import StandardButton from "@/components/buttons/StandardButton";

export default {
  data() {
    return {};
  },
  props: {
    disabled: { type: Boolean, default: false },
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    subtitleHtml: { type: String, default: "" },
    buttonName: { type: String, default: "Continue" },
  },

  components: {
    StandardButton,
  },

  methods: {
    emitContinue() {
      this.$emit("continue");
    },
  },
};
</script>
