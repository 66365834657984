export const IronchipService = {
  alias: "Ironchip Authenticator",
  external_id: "Ironchip Authenticator",
};

export const DashboardService = {
  alias: "Ironchip Dashboard",
  external_id: "Ironchip Dashboard",
};

export const SERVICE_TYPE_APIKEY = "apikeys",
  SERVICE_TYPE_OIDC = "oidc",
  SERVICE_TYPE_MFA = "http-mfa",
  SERVICE_TYPE_SAML = "saml";

export default class ServiceModel {
  constructor(
    id,
    name,
    externalName,
    companyId,
    minimumKeyTypes,
    type,
    integration,
    data,
    imageURL,
  ) {
    this.id = id;
    this.name = name;
    this.externalName = externalName;
    this.companyId = companyId;
    this.minimumKeyTypes = minimumKeyTypes;
    this.type = type;
    this.integration = integration;
    this.data = data;
    this.imageURL = imageURL;
  }
}