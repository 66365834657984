import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#95C11F",
        grey: "#e1e1e1",
        opacity: 0.5,
        error: "#FF0000",
        dimgray: "#696969",
        // darkgrey: '#666e78',
        darkgrey: "#595959",
        lightgrey: "#a8acac",
      },
    },
  },
});
