import { debounce } from "lodash";
import Breakpoint from "../models/viewportModel";

export const SM = "sm",SM_MD="sm-md",MD="md",MD_LG="md-lg",LG="lg",XL="xl";
export const BREAKPOINTS = [ 
  new Breakpoint(SM, 0, 500),
  new Breakpoint(SM_MD, 500, 600),
  new Breakpoint(MD, 600, 700),
  new Breakpoint(MD_LG, 700, 800),
  new Breakpoint(LG, 800, 900),
  new Breakpoint(XL, 900, 1000),
];


export default class ViewportService {
  constructor(){
    this.currentBreakpoint = undefined;
    this._init();
  }

  _init() {
    this.computeViewport(window.innerHeight);
    window.addEventListener('resize', debounce(()=>{this.computeViewport(window.innerHeight)}, 500));
  }
  
  computeViewport(height){
    this.currentBreakpoint = undefined;
    for(let index in BREAKPOINTS){
      if(BREAKPOINTS[index].isInRange(height)){
        this.__setCurrentBreakpoint(BREAKPOINTS[index]);
        break;
      }
    }
  }

  __setCurrentBreakpoint(breakpoint){
    this.currentBreakpoint = breakpoint;
  }

  getCurrentBreakpointName(){
    try{
      return this.currentBreakpoint.name;
    }catch{
      return undefined;
    }
  };

  getRowsPerPage(sm, sm_md, md, md_lg, lg, xl, other){
    let vHeight = this.getCurrentBreakpointName();
    switch (vHeight) {
      case SM:
        return sm;
      case SM_MD:
        return sm_md;
      case MD:
        return md;
      case MD_LG:
        return md_lg;
      case LG:
        return lg;
      case XL:
        return xl;
      default:
        return other;
    }
  }
}