export default class AccessModel {
  constructor(
    id,
    groupName,
    externalUserName,
    externalUsernameLayoutLabel,
    serviceExternalName,
    groupId,
    serviceId,
    companyId,
    optional_required_key_tags
  ) {
    this.companyId = companyId;
    this.externalUserName = externalUserName;
    this.externalUsernameLayoutLabel = externalUsernameLayoutLabel;
    this.groupId = groupId;
    this.groupName = groupName;
    this.id = id;
    this.optional_required_key_tags = optional_required_key_tags;
    this.serviceExternalName = serviceExternalName;
    this.serviceId = serviceId;
  }
}
