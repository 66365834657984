export const GROUP_TYPE_COMMONS = "Commons",
  GROUP_TYPE_LDAP = "LDAP";

export default class KeyGroupModel {
  constructor(id, name, type = GROUP_TYPE_COMMONS, configuration = {}) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.configuration = configuration;
  }

  static modelToJSON(keyGroupModel) {
    return {
      id: keyGroupModel.id,
      name: keyGroupModel.name,
      type: keyGroupModel.type,
      ldap_configuration: keyGroupModel.configuration,
    };
  }

  static JSONToModel(JSON) {
    return new KeyGroupModel(JSON.id, JSON.name, JSON.type, JSON.ldap_configuration);
  }
}