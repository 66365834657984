<template>
  <tr @click="onRowClicked">
    <slot></slot>
  </tr>
</template>

<script>
import ICell from "./ICell";
export default {
  components: {
    ICell,
  },
  methods: {
    onRowClicked() {
      this.$emit("onRowClicked");
    },
  },
};
</script>
