import TimePeriod from "./timePeriodModel";

const INVOICE = {
  WAITING: "Waiting-manual",
  PAID: "Paid",
  PENDING: "Pending",
};
Object.freeze(INVOICE);

export const INVOICESTATUS = INVOICE;

class InvoiceExtras {
  constructor(
    options = {
      _id: "",
      extra_name: "",
      extra_amount: 0,
      extra_price: 0,
    }
  ) {
    this.extraId = options._id;
    this.name = options.extra_name;
    this.quantity = options.extra_amount;
    this.unitPrice = options.extra_price;
  }
}

export default class InvoiceModel {
  constructor(
    options = {
      _id: null,
      company_id: null,
      invoice_number: null,
      invoice_period: null,
      invoice_status: null,
      invoice_s3_uri: null,
      invoice_extras: null,
      enabled_users: 0,
    }
  ) {
    this.id = options._id;
    this.companyId = options.company_id;
    this.number = options.invoice_number;
    this.invoicingPeriod = new TimePeriod(options.invoice_period);
    this.status = options.invoice_status;
    this.URI = options.invoice_s3_uri || "/assets/docs/invoice-mockup.pdf";
    this.extras = options.invoice_extras.map((item) => {
      return new InvoiceExtras(item);
    });
    this.activeUsers = options.enabled_users;
  }

  getTotalPrice(unitPrice) {
    return (
      this.activeUsers * unitPrice +
      this.extras.reduce((acc, cur) => {
        return acc + cur.quantity * cur.unitPrice;
      }, 0)
    );
  }
}
