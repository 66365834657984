export default class TimePeriod {
  constructor(
    options = {
      started_date: null,
      end_date: null,
    }
  ) {
    this.start = options.started_date;
    this.end = options.end_date;
  }
}
