const state = {
  firstTimeTutorial: true,
  dashboardWalkthrough: false,
  homeTutorial: false,
  userAdditionTutorial: false,
  userRecoveryTutorial: false,
  servicesTutorial: false,
  keysTutorial: false,
  integrationsTutorial: false,
  accessesTutorial: false,
};

const mutations = {
  setFirstTimeTutorial: (state, payload) => {
    state.firstTimeTutorial = payload;
  },
  setDashboardWalkthrough: (state, payload) => {
    state.dashboardWalkthrough = payload;
  },
  setHomeTutorial: (state, payload) => {
    state.homeTutorial = payload;
  },
  setUserAdditionTutorial: (state, payload) => {
    state.userAdditionTutorial = payload;
  },
  setUserRecoveryTutorial: (state, payload) => {
    state.userRecoveryTutorial = payload;
  },
  setServicesTutorial: (state, payload) => {
    state.servicesTutorial = payload;
  },
  setKeysTutorial: (state, payload) => {
    state.keysTutorial = payload;
  },
  setIntegrationsTutorial: (state, payload) => {
    state.integrationsTutorial = payload;
  },
  setAccessesTutorial: (state, payload) => {
    state.accessesTutorial = payload;
  },
  dismissTutorials: (state) => {
    state.firstTimeTutorial = false;
    state.dashboardWalkthrough = false;
    state.homeTutorial = false;
    state.userAdditionTutorial = false;
    state.userRecoveryTutorial = false;
    state.servicesTutorial = false;
    state.keysTutorial = false;
    state.integrationsTutorial = false;
    state.accessesTutorial = false;
  },
};

const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
