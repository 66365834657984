import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import errorsService from "./errorsService";
import { Centrifuge } from "centrifuge";

export const NOTIFICATION = {
  BILLING_DETAILS_UPDATED: "CompanyInvoicingDetailsUpdated",
  COMPANY_ADDED: "CompanyAdded",
  COMPANY_LICENSE_ADDED: "CompanyLicenseAdded",
  COMPANY_SERVICE_ADDED: "CompanyServiceAdded",
  COMPANY_SERVICE_UPDATED: "CompanyServiceUpdated",
  COMPANY_SERVICE_DELETED: "CompanyServiceDeleted",
  ACCESS_ADDED: "AccessAdded",
  ACCESS_UPDATED: "AccessUpdated",
  ACCESS_DELETED: "AccessDeleted",
  API_KEY_ADDED: "ApikeyAdded",
  API_KEY_DELETED: "ApikeyDeleted",
  COMPANY_UPDATED: "CompanyUpdated",
  COMPANY_HTTP_MFA_INTEGRATION_ADDED: "CompanyHTTPMFAIntegrationAdded",
  COMPANY_HTTP_MFA_INTEGRATION_DELETED: "CompanyHTTPMFAIntegrationDeleted",
  IMPORT_REPORT_ADDED: "UserSyncStarted",
  IMPORT_REPORT_CHANGED: "UserSyncUpdated",
  KEY_ADDED: "KeyAdded",
  KEY_UPDATED: "KeyUpdated",
  KEY_DELETED: "KeyDeleted",
  KEY_REPLACED: "KeyReplaced",
  KEY_TAG_ADDED: "KeyGroupAdded",
  KEY_TAG_UPDATED: "KeyGroupUpdated",
  KEY_TAG_DELETED: "KeyGroupDeleted",
  KEY_TAG_KEY_ADDED: "KeyGroupUpdatedKeysIDsAdded",
  KEY_TAG_KEY_DELETED: "KeyGroupUpdatedKeysIDsDeleted",
  ENROLLMENT_REGISTERED: "EnrollmentRegistered",
  ENROLLMENT_EMAIL_REGISTERED: "EnrollmentEmailRegistered",
  ENROLLMENT_VALIDATED: "EnrollmentValidated",
  ENROLLMENT_UPDATED: "EnrollmentUpdated",
  ENROLLMENT_DELETED: "EnrollmentDeleted",
  GROUP_ADDED: "GroupAdded",
  GROUP_DELETED: "GroupDeleted",
  GROUP_UPDATED: "GroupUpdated",
  GROUP_CHANGE_EVENT: "groupsChanged",
  GROUP_MEMBER_ADDED: "GroupUserIDsAdded",
  GROUP_MEMBER_DELETED: "GroupUserIDsDeleted",
  OBJECT_STORAGE_ADDED: "ObjectStorageAdded",
  RECOVERY_CONFIGURED: "RecoveryConfigured",
  RECOVERY_SENT: "RecoverySent",
  RECOVERY_VALIDATED: "RecoveryValidated",
  SESSION_ADDED: "SessionAdded",
  SESSION_DELETED: "SessionDeleted",
  USER_ADDED: "UserAdded",
  USER_UPDATED: "UserUpdated",
  USER_DELETED: "UserDeleted",
  USER_EXPORT_FINISHED: "UserExportFinished",
  AUTHORIZATION_STARTED: "AuthorizationStarted",
  AUTHORIZATION_KEY_PROVIDED: "AuthorizationKeyProvided",
  AUTHORIZATION_CONSUMED: "AuthorizationConsumed",
  AUTHORIZATION_NOTIFIED: "AuthorizationNotified",
  PERMISSION_ADDED: "PermissionAdded",
  PERMISSION_UPDATED: "PermissionUpdated",
  PERMISSION_DELETED: "PermissionDeleted",
};

export default class NotificationService {
  constructor() {
    this.currentSubscribedChannels = new Set();
    this.registeredNotificationHandlers = new Map();
    this.connectionToken = "";
    this.subscriptionTokens = new Map();
    this.NOTIFICATION_SERVICE_INITIALIZATION_ERROR = new Error(
      "Failed to initialize the Notification Service"
    );

    this._getSessionToken().then(() => {
      if (this.connectionToken == null) return;

      this._setNotificationSource(config.VUE_APP_NOTIFICATIONS_SOURCE);
      this._setNotificationSourceHandlers();
      this.connect();
    });
  }

  _setNotificationSource(webSocketURL) {
    this.centrifuge = new Centrifuge(webSocketURL, {
      token: this.connectionToken,
      debug: false, //SET TO false IN PROD ENV
    });
  }

  _getSessionToken() {
    return ironchipsdk
      .GETMeNotificationsToken()
      .then((response) => {
        this.connectionToken = response.connection_token;
        this.subscriptionTokens = response.subscription_tokens;
      })
      .catch((error) => {
        //404
        console.error(
          "[NOTIFICATION SERVICE] - Error requesting new session token."
        );
        console.error(error);
        this.connectionToken = null;
        throw this.NOTIFICATION_SERVICE_INITIALIZATION_ERROR;
      });
  }

  _setNotificationSourceHandlers() {
    this.centrifuge.on("connected", function (ctx) {
      // DO SMTH ON CONNECT
    });

    this.centrifuge.on("disconnected", function (ctx) {
      // DO SMTH ON DISCONNECT
    });
  }

  connect() {
    this.centrifuge.connect();
  }

  disconnect() {
    this.centrifuge.disconnect();
  }

  subscribeToChannel(companyID, channelID) {
    if (
      this.centrifuge.getSubscription(channelID) ||
      this.centrifuge.getSubscription(`company:${channelID}`)
    )
      return;

    const subscriptionID =
      channelID == companyID ? `company:${channelID}` : channelID;

    const sub = this.centrifuge.newSubscription(subscriptionID, {
      token: this.subscriptionTokens[subscriptionID],
    });

    sub.on("publication", this.subscriptionCallbacks.publish);
    sub.on("join", this.subscriptionCallbacks.join);
    sub.on("leave", this.subscriptionCallbacks.leave);
    sub.on("subscribed", this.subscriptionCallbacks.subscribe);
    sub.on("error", this.subscriptionCallbacks.error);
    sub.on("unsubscribed", this.subscriptionCallbacks.unsubscribe);

    sub.subscribe();
  }

  get subscriptionCallbacks() {
    return {
      publish: this.onPublishNotificationReceived.bind(this),
      join: this.onJoinNotificationReceived.bind(this),
      leave: this.onLeaveNotificationReceived.bind(this),
      subscribe: this.onSubscribeNotificationReceived.bind(this),
      error: this.onErrorNotificationReceived.bind(this),
      unsubscribe: this.onUnsubscribeNotificationReceived.bind(this),
    };
  }

  onPublishNotificationReceived(notification) {
    const newNotification = notification.data;
    if (!this.registeredNotificationHandlers.get(newNotification.type))
      console.warn(
        `[WARNING] No handlers registered for notification type: "${newNotification.type}"`
      );
    //TRIGGER APPROPRIATE HANDLER
    if (this.registeredNotificationHandlers.get(newNotification.type)) {
      this.registeredNotificationHandlers
        .get(newNotification.type)
        .forEach((handler) => {
          try {
            handler.handle(newNotification);
          } catch (error) {
            handler.handleError(newNotification);
            console.error(error);
            errorsService.onJavaScriptError(error);
          } finally {
          }
        });
    }
  }

  onJoinNotificationReceived(notification) {}

  onLeaveNotificationReceived(notification) {}

  onSubscribeNotificationReceived(notification) {
    if (this.currentSubscribedChannels.has(notification.channel)) return;

    this.currentSubscribedChannels.add(notification.channel);
  }

  onErrorNotificationReceived(notification) {
    console.error(
      "[NOTIFICATION SERVICE] Error:",
      `[${notification.channel}:${notification.message} - Code: ${notification.code}]`
    );
  }

  onUnsubscribeNotificationReceived(notification) {
    this.currentSubscribedChannels.delete(notification.channel);
  }

  registerNotificationHandler(eventType, handler, errorHandler) {
    let handlerList = this.registeredNotificationHandlers.get(eventType);
    if (!handlerList) {
      handlerList = [];
    }

    handlerList.push({
      handle: handler,
      handleError: errorHandler ? errorHandler : function () {},
    });

    this.registeredNotificationHandlers.set(eventType, handlerList);
  }

  get notificationTypes() {
    return NOTIFICATION;
  }

  //////DELETE THIS
  get localEvents() {
    return {
      ACCESS_CHANGED: "accessChanged",
      AUTHORIZATION_CHANGED: "authorizationChanged",
      COMPANY_CHANGED: "companyChanged",
      IDS_REPORT_CHANGED: "idsReportChanged",
      IDS_LOG_CHANGED: "idsLogChanged",
      COMPANY_HTTP_MFA_INTEGRATION_CHANGED: "integrationChanged", //
      KEY_CHANGED: "keyChanged",
      COMPANY_SERVICE_CHANGED: "serviceChanged",
      IMPORT_REPORT_CHANGED: "importStarted",
      USER_CHANGED: "userChanged",
    };
  }

  get localEventEquivalence() {
    return new Map([
      [this.notificationTypes.USER_ADDED, this.localEvents.USER_CHANGED],
      [this.notificationTypes.USER_UPDATED, this.localEvents.USER_CHANGED],
      [this.notificationTypes.USER_DELETED, this.localEvents.USER_CHANGED],
    ]);
  }
}
