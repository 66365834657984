import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import CompanyModel from "@/models/companyModel";
import { COMPANY_UPDATED } from "./eventsService";
import { SECONDS } from "./timeService.js";

const COMPANY_SERVICE_INITIALIZATION_ERROR = new Error(
  "Error initializing company service"
);

export const PLANS = {
  BASIC: "Basic",
  ENTERPRISE: "Enterprise",
  PREMIUM: "Premium",
};

export default class CompanyService {
  constructor(eventsService, notificationService, timeService) {
    this.company = {};
    this.canReadCompany = false;
    this.companyChangeEvent = new CustomEvent("companyChanged");

    this._initializeCompany();

    this._initializeCompanyUpdatedHandler(eventsService, notificationService);
    this.timeService = timeService;
  }

  getCompany() {
    return this.company;
  }

  async _initializeCompany() {
    await ironchipsdk
      .GETMeCompany()
      .then((resp) => {
        this.canReadCompany = true;
        this.company = new CompanyModel(
          resp.id,
          resp.name,
          resp.lang,
          resp.license,
          resp.sync_apikey
        );
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.canReadCompany = false;
          this.company = new CompanyModel(0, "Unknown company", "", {}, null);
        } else {
          let serviceInitError = new CustomEvent("serviceInitError", {
            detail: COMPANY_SERVICE_INITIALIZATION_ERROR,
          });
          window.dispatchEvent(serviceInitError);
        }
      })
      .finally(() => {
        window.dispatchEvent(
          new CustomEvent("companyChanged", {
            detail: {
              company: this.company,
            },
          })
        );
      });
  }

  //BILLING FEATURE
  async fetchCompanyBillingDetails() {
    await ironchipsdk
      .GETMeCompanyBillingDetails()
      .then((resp) => {})
      .catch((err) => {});
  }

  async getCompanyBillingDetails() {
    /* return this.details */
  }

  async postCompanyBillingDetails() {
    await ironchipsdk
      .POSTMeCompanyBillingDetails()
      .then((resp) => {})
      .catch((err) => {});
  }

  _initializeCompanyUpdatedHandler(eventsService, notificationService) {
    //TODO IMPLEMENT REAL HANDLER
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.COMPANY_UPDATED,
      function (notification) {
        this.company = {};
        // store.commit("companyChange");
      }.bind(this),
      null
    );
  }
}
