import {
  AUTHORIZATION_STARTED,
  AUTHORIZATION_KEY_PROVIDED,
  AUTHORIZATION_CONSUMED,
} from "./eventsService";
import { FORMAT } from "./timeService";
import { TimelineModel } from "../models/timelineModel";
import UserModel from "../models/userModel";

const LIMIT = 50;

export default class AuthorizationEventService {
  constructor(
    eventsService,
    companyServicesService,
    usersService,
    keysService,
    timeService,
    notificationService
  ) {
    this.eventsService = eventsService;
    this.companyServicesService = companyServicesService;
    this.usersService = usersService;
    this.keysService = keysService;
    this.timeService = timeService;
    this.authorizationEvents = new Map();
    this.lastEventTimestamp = this.timeService.currentMicrosFromEpoch;

    this._initializeAuthorizationStartedHandler(
      eventsService,
      notificationService
    );
    this._initializeAuthorizationKeyProvidedHandler(
      eventsService,
      notificationService
    );
    this._initializeAuthorizationConsumedHandler(
      eventsService,
      notificationService
    );
  }

  _initializeAuthorizationStartedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.AUTHORIZATION_STARTED,
      function (notification) {
        this._onAuthorizationEventAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeAuthorizationKeyProvidedHandler(
    eventsService,
    notificationService
  ) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.AUTHORIZATION_KEY_PROVIDED,
      function (notification) {
        this._onAuthorizationEventAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeAuthorizationConsumedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.AUTHORIZATION_CONSUMED,
      function (notification) {
        this._onAuthorizationEventAdded(notification);
      }.bind(this),
      null
    );
  }

  _onAuthorizationEventAdded(authorizationEvent) {
    let newEvent = {
      event: {
        id: authorizationEvent.id,
        company_id: authorizationEvent.company_id,
        time: 1659340583247445200,
        type: authorizationEvent.type,
        data: {
          access_id: authorizationEvent.data.access_id,
          code: authorizationEvent.data.code,
          id: authorizationEvent.data.id,
          id_identified_key: authorizationEvent.data.id_identified_key,
          score: authorizationEvent.data.score,
          service_id: authorizationEvent.data.service.id,
          external_username: authorizationEvent.data.external_username_label,
          start_time: authorizationEvent.data.start_time,
          expiration_time: authorizationEvent.data.expiration_time,
          valid: authorizationEvent.data.valid,
          authorized: authorizationEvent.data.authorized,
        },
      },
      user_info: {
        key_name:
          authorizationEvent.data.key != null
            ? authorizationEvent.data.key.name
            : null,
        key_type:
          authorizationEvent.data.key != null
            ? authorizationEvent.data.key.type
            : null,
        user_name:
          authorizationEvent.data.user != null
            ? authorizationEvent.data.user.name
            : null,
        user_surname:
          authorizationEvent.data.user != null
            ? authorizationEvent.data.user.surname
            : null,
      },
    };

    this.authorizationEvents.set(authorizationEvent.id, newEvent);

    const eventTimestamp =
      this.timeService.getTimestampFormat(authorizationEvent.timestamp) ===
      FORMAT.SECS
        ? authorizationEvent.timestamp * 1000000000
        : authorizationEvent.timestamp;

    if (eventTimestamp < this.lastEventTimestamp) {
      this.lastEventTimestamp = eventTimestamp;
    }

    let newAuthorizationEvent = new CustomEvent("authorizationChanged", {
      detail: newEvent,
    });
    window.dispatchEvent(newAuthorizationEvent);
  }

  async _populateAuthEvents() {
    let events = await this.eventsService.getCompanyEvents(
      LIMIT,
      this.lastEventTimestamp
    );

    events.forEach((event) => {
      if (event.event.time < this.lastEventTimestamp) {
        this.lastEventTimestamp = event.event.time;
      }
      if (
        event.event.type === AUTHORIZATION_STARTED ||
        event.event.type === AUTHORIZATION_KEY_PROVIDED ||
        event.event.type === AUTHORIZATION_CONSUMED
      ) {
        this.authorizationEvents.set(event.event.id, event);
      }
    });
  }

  async getCompanyTimelineModels(requiredModelsLength) {
    let countAuthModels = this._countAuthorizationModels();
    let newCountAuthModels = countAuthModels;

    while (requiredModelsLength > countAuthModels) {
      await this._populateAuthEvents();

      newCountAuthModels = this._countAuthorizationModels();
      if (newCountAuthModels === countAuthModels) {
        break;
      }

      countAuthModels = newCountAuthModels;
    }

    let currentEvents = Array.from(this.authorizationEvents.values());

    return Array.from(
      currentEvents
        .sort((event1, event2) => {
          if (event1.event.time - event2.event.time === 0)
            return event1.event.type === AUTHORIZATION_STARTED ? -1 : 1;

          return event1.event.time - event2.event.time;
        })
        .map((sortedEvent) => {
          switch (sortedEvent.event.type) {
            case AUTHORIZATION_STARTED:
              return this._generatePartialModelFromAuthStarted(sortedEvent);

            case AUTHORIZATION_KEY_PROVIDED:
              return this._generatePartialModelFromAuthKeyProvided(sortedEvent);

            case AUTHORIZATION_CONSUMED:
              return this._generatePartialModelFromAuthConsumed(
                sortedEvent.event
              );
          }
        })
        .map((sortedPartialEvent) => {
          switch (sortedPartialEvent.type) {
            case AUTHORIZATION_STARTED:
              return this._aggregateTimelineModelFromAuthStarted(
                sortedPartialEvent
              );

            case AUTHORIZATION_KEY_PROVIDED:
            // return this._aggregateTimelineModelFromAuthKeyProvided(sortedPartialEvent)

            case AUTHORIZATION_CONSUMED:
              return this._aggregateTimelineModelFromAuthConsumed(
                sortedPartialEvent
              );
          }
        })
        .reduce((accumulator, aggregatedPartialEvent) => {
          if (
            aggregatedPartialEvent.type !== AUTHORIZATION_STARTED &&
            !accumulator.has(aggregatedPartialEvent.authId)
          )
            return accumulator;
          if (
            aggregatedPartialEvent.type === AUTHORIZATION_KEY_PROVIDED &&
            accumulator
              .get(aggregatedPartialEvent.authId)
              .providedKeys.has(aggregatedPartialEvent.id)
          )
            return accumulator;

          let timelineModel;
          switch (aggregatedPartialEvent.type) {
            case AUTHORIZATION_STARTED:
              accumulator.set(
                aggregatedPartialEvent.authId,
                aggregatedPartialEvent
              );
              break;

            case AUTHORIZATION_KEY_PROVIDED:
              timelineModel = accumulator.get(aggregatedPartialEvent.authId);
              timelineModel.providedKeys.set(
                aggregatedPartialEvent.id,
                aggregatedPartialEvent
              );

              timelineModel.providerUserName =
                aggregatedPartialEvent.providerUserName;

              timelineModel.providerUserSurname =
                aggregatedPartialEvent.providerUserSurname;

              accumulator.set(aggregatedPartialEvent.authId, timelineModel);
              break;

            case AUTHORIZATION_CONSUMED:
              timelineModel = accumulator.get(aggregatedPartialEvent.authId);
              timelineModel.consumed = aggregatedPartialEvent.consumed;
              accumulator.set(aggregatedPartialEvent.authId, timelineModel);
              break;
          }

          return accumulator;
        }, new Map())
        .values()
    ).map((reducedEvent) => {
      return new TimelineModel(reducedEvent);
    });
  }

  _countAuthorizationModels() {
    return Array.from(this.authorizationEvents.values()).reduce(
      (accumulator, event) => {
        let eventId = event.event ? event.event.data.id : event.data.id;
        if (!accumulator.has(eventId)) {
          accumulator.set(eventId, true);
        }

        return accumulator;
      },
      new Map()
    ).size;
  }

  _generatePartialModelFromAuthStarted(sortedEvent) {
    return {
      id: sortedEvent.event.id,
      type: sortedEvent.event.type,
      authId: sortedEvent.event.data.id,
      startedAt: sortedEvent.event.data.start_time,
      expirationTime: sortedEvent.event.data.expiration_time,
      userId: sortedEvent.event.data.user_id,
      name: sortedEvent.user_info.user_name,
      surname: sortedEvent.user_info.user_surname,
      externalUsername: sortedEvent.event.data.external_username,
      serviceId: sortedEvent.event.data.service_id,
      providedKeys: new Map(),
      consumed: false,
    };
  }

  _generatePartialModelFromAuthKeyProvided(sortedEvent) {
    return {
      id: sortedEvent.event.id,
      type: sortedEvent.event.type,
      authId: sortedEvent.event.data.id,
      identifiedKeyId: sortedEvent.event.data.id_identified_key,
      keyName: sortedEvent.user_info.key_name,
      keyType: sortedEvent.user_info.key_type,
      score: (sortedEvent.event.data.score * 100).toFixed(2),
      valid: sortedEvent.event.data.valid,
      time: sortedEvent.event.time,
      providerUserName: sortedEvent.user_info.user_name,
      providerUserSurname: sortedEvent.user_info.user_surname,
    };
  }

  _generatePartialModelFromAuthConsumed(sortedEvent) {
    return {
      id: sortedEvent.id,
      type: sortedEvent.type,
      authId: sortedEvent.data.id,
      consumed: sortedEvent.data.authorized,
    };
  }

  _aggregateTimelineModelFromAuthStarted(sortedPartialEvent) {
    let service = this.companyServicesService.getService(
      sortedPartialEvent.serviceId
    );
    if (!service) {
      sortedPartialEvent.serviceName = "Unknown";
    } else {
      sortedPartialEvent.serviceName = service.name;
      sortedPartialEvent.serviceLogo = service.imageURL;
    }

    return sortedPartialEvent;
  }

  _aggregateTimelineModelFromAuthKeyProvided(sortedPartialEvent) {
    let key = this.keysService.getKeyById(sortedPartialEvent.identifiedKeyId);

    if (!key) {
      sortedPartialEvent.keyName = "Unknown";
    } else {
      sortedPartialEvent.keyName = key.data.name;
      sortedPartialEvent.keyType = key.type;
    }

    return sortedPartialEvent;
  }

  _aggregateTimelineModelFromAuthConsumed(sortedPartialEvent) {
    return sortedPartialEvent;
  }
}
