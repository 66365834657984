import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/app.scss";
import "vue-ionicons/ionicons.scss";
import "@mdi/font/css/materialdesignicons.css";
import vuetify from "./plugins/vuetify";
import connectionChecker from "./plugins/connectionCheck/connectionChecker";
import ICell from "@/components/common/table/ICell";
import IHeader from "@/components/common/table/IHeader";
import IRow from "@/components/common/table/IRow";
import ITable from "@/components/common/table/ITable";
import ISearchBar from "@/components/common/ISearchBar";
import ITooltip from "@/components/common/ITooltip";
import initSDK from "./utils/initSDK";
import Icons from "./utils/icons";
import { initServicesOnDevelopment } from "./utils/userManagement";
import errorsService from "./services/errorsService";
// APEXCHART
import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);
Vue.component("ITable", ITable);
Vue.component("IHeader", IHeader);
Vue.component("IRow", IRow);
Vue.component("ICell", ICell);
Vue.component("ISearchBar", ISearchBar);
Vue.component("ITooltip", ITooltip);
//SHEPHERD
import VueShepherd from "vue-shepherd";

// IMPORT APIS
Vue.use(initSDK, {
  store: store,
  iconfont: "mdi",
});

// Vue errors handler
Vue.config.errorHandler = function(err, vm, info) {
  errorsService.onVueError(err, info);
};

window.onerror = function(error) {
  errorsService.onJavaScriptError(error);
};

window.onunhandledrejection = function(error) {
  errorsService.onUnhandledRejectionError(error);
};

// VUE CONFIGURATION
const isInDevelopmentMode = (process.env.NODE_ENV === "development");

Vue.config.silent = !isInDevelopmentMode;
Vue.config.productionTip = false;
Vue.config.devtools = isInDevelopmentMode;
Vue.config.debug = isInDevelopmentMode;

initServicesOnDevelopment().finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");

  Vue.use(Icons);
  Vue.use(VueShepherd);
  Vue.use(connectionChecker);
});