import { render, staticRenderFns } from "./StandardButton.vue?vue&type=template&id=886ad4ae&scoped=true"
import script from "./StandardButton.vue?vue&type=script&lang=js"
export * from "./StandardButton.vue?vue&type=script&lang=js"
import style0 from "./StandardButton.vue?vue&type=style&index=0&id=886ad4ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "886ad4ae",
  null
  
)

export default component.exports