<template>
  <div>
    <v-row v-if="searchable">
      <v-col v-if="!hideSearchBar" cols class="align-self-center py-0">
        <i-search-bar
          v-if="!multifilters"
          ref="searchbar"
          :autoSearch="autoSearch"
          :searchPlaceholder="searchPlaceholder"
          @requestSearch="onSearchRequested" />
        <SearchFilter
          v-else
          :searchTokens="searchTokens"
          ref="searchbar"
          :resultDownload="downloadable"
          :tableId="tableId"
          :autoSearch="autoSearch"
          :searchPlaceholder="searchPlaceholder"
          @requestSearch="onMultiSearchRequested"
          @requestDownload="onCSVDownloadRequested" />
      </v-col>
      <slot name="control-btns"></slot>
    </v-row>

    <div>
      <v-simple-table :id="id" class="mt-3" :height="tableHeight" fixed-header>
        <template v-slot:default>
          <slot></slot>
          <tr v-if="emptyContent">
            <td :colspan="columns" class="text-center py-4">
              <v-icon class="mr-2">{{ noDataIcon }}</v-icon>
              <span>{{ noDataMessage }}</span>
            </td>
          </tr>
        </template>
      </v-simple-table>

      <div v-if="totalPages" class="text-center mb-2">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          :total-visible="totalVisibleSteps"
          color="#005255"
          @next="emitPageChange('nextPage')"
          @previous="emitPageChange('prevPage')" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchFilter from "@/components/common/table/ISearchFilter.vue";

export default {
  components: { SearchFilter },
  props: {
    tableId: {
      type: String,
      default: "searchInput",
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    searchable: Boolean,
    multifilters: Boolean,
    downloadable: Boolean,
    searchTokens: {
      type: Array,
      default: () => [],
    },
    autoSearch: {
      type: Boolean,
      default: true,
    },
    hideSearchBar: {
      type: Boolean,
      default: false,
    },
    totalPages: Number,
    totalVisibleSteps: {
      type: Number,
      default: 7,
    },
    tableHeight: String,
    id: {
      type: String,
      required: true,
    },
    noDataMessage: {
      type: String,
      default: "No data found",
    },
    noDataIcon: {
      type: String,
      default: "mdi-alert-box",
    },
  },
  data() {
    return {
      searchText: "",
      currentPage: 1,
      emptyContent: false,
      columns: 1,
    };
  },
  methods: {
    onSearchRequested(text) {
      this.$emit("onSearchRequested", text);
    },
    onMultiSearchRequested(filterArray) {
      this.currentPage = 1;
      this.$emit("onMultiSearchRequested", filterArray);
    },
    onCSVDownloadRequested(filterArray) {
      this.$emit("onCSVDownloadRequested", filterArray);
    },
    clearSearchBar() {
      this.$refs.searchbar.debouncedSearchText = "";
      this.$refs.searchbar.searchText = "";
    },
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    emitPageChange(direction) {
      this.$emit(direction);
    },
  },
  watch: {
    totalPages: function () {
      this.currentPage = 1;
    },
    currentPage: function (newValue) {
      this.$emit("onPageChanged", newValue);
    },
  },
  updated() {
    this.emptyContent =
      document.getElementById(this.id).querySelector("[name='cell']") == null;
    this.columns = document
      .getElementById(this.id)
      .querySelectorAll("[name='table-header']").length;
  },
};
</script>

<style>
.v-pagination__item {
  height: 20px;
  min-width: 20px;
  margin: 5px !important;
  padding: 0px !important;
  font-size: 14px;
}

.v-pagination__navigation {
  height: 20px;
  min-width: 20px;
  margin: 5px !important;
  padding: 0px !important;
  font-size: 12px;
}
</style>
