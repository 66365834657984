export const ROUTENAMES = {
  LOGIN: "Login",
  CALLBACK: "Callback",
  RECOVERYSUCCESS: "RecoverySuccess",
  RECOVERY: "Recovery",
  APPRECOVERY: "AppRecovery",
  VERIFY: "Verify",
  EMAILVERIFICATION: "EmailVerification",
  VALIDATION: "Validation",
  GETSTARTED: "GetStarted",
  METRICS: "Metrics",
  LICENSES: "Licenses",
  APPLICATIONS: "Applications",
  SERVICEDETAIL: "ServiceDetail",
  USERACCESS: "UserAccess",
  USERS: "Users",
  USERDETAIL: "UserDetail",
  KEYS: "Keys",
  DEVICES: "Devices",
  KEYGROUPS: "KeyGroups",
  GROUPS: "Groups",
  IDS: "IDS",
  PERMISSIONS: "Permissions",
  LOGS: "Logs",
  SETTINGS: "Settings",
  PLUGINS: "Plugins",
  INTEGRATIONS: "Integrations",
  WANTIT: "WantIt",
  WANTITERROR: "WantItError",
  TIMEOUTLANDINGPAGE: "TimeOutPage",
  PAYMENTSUCCESS: "PaymentSuccess",
  PAYMENTCANCELLED: "PaymentCancelled",
};
