import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import Vue from "vue";
import services from "../services";
import store from "../store";
import RequestRecoveryModel from "@/models/requestRecoveryModel";
import UserModel from "@/models/userModel";

let user = {};

export async function login(email) {
  return await ironchipsdk
    .Login(email)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
}

export async function launchLoginCallback(callbackURL) {
  return await ironchipsdk
    .checkLoginCallback(callbackURL)
    .then(async (resp) => {
      if (await _initializeUser()) {
        Vue.use(services);
        if (process.env.NODE_ENV === "development") {
          _setUserInLocalStorage();
        }
      }
      return resp;
    })
    .catch((err) => {
      throw err;
    });
}

export function isLoginStateValid(lastState) {
  return ironchipsdk.checkLoginStateIntegrity(lastState);
}

export function loginByEmail(callbackURL, tempSessionToken) {
  return ironchipsdk.validateEmailLogin(callbackURL, tempSessionToken);
}

export async function getUser() {
  if (process.env.NODE_ENV === "development") {
    return store.state.auth.user;
  } else {
    return user;
  }
}

export async function initServicesOnDevelopment() {
  if (
    process.env.NODE_ENV === "development" &&
    (await ironchipsdk.isLogged()) &&
    (await ironchipsdk.HEADMeCompanyTokenValidation()) === 200
  ) {
    // INIT SERVICES ON REFRESH
    Vue.use(services);
    await _initializeUser();
    _setUserInLocalStorage();
  }
}

export function logout() {
  return ironchipsdk
    .logout()
    .then(() => {
      location.reload();
      store.commit("logout");
      store.commit("isUsersImportActive", false);
      store.commit("setUsersImportID", null);
    })
    .catch((err) => {
      throw err;
    });
}

export async function recovery(email) {
  let requestRecovery = new RequestRecoveryModel(email);
  await ironchipsdk
    .Recovery(requestRecovery)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
}

export async function setEnrollmentEventSource(nonce) {
  return await ironchipsdk
    .SetEnrollmentCompletedEventSource(nonce)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
}

async function _initializeUser() {
  return await ironchipsdk
    .GETMeCompanyUser()
    .then((userResponse) => {
      const userModel = new UserModel(
        userResponse.id,
        userResponse.name,
        userResponse.surname,
        userResponse.email,
        userResponse.external_email,
        userResponse.lang,
        userResponse.immutable_id,
        userResponse.position,
        userResponse.roles,
        userResponse.company_id,
        userResponse.verified,
        userResponse.ironchip_enabled,
        userResponse.sam_account_name,
        userResponse.user_principal_name
      );
      user = userModel;
      return true;
    })
    .catch((err) => {
      throw err;
    });
}

function _setUserInLocalStorage() {
  store.commit("user", user);
}
