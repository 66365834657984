<template>
  <th
    name="table-header"
    class="py-2"
    :class="centered ? 'text-center' : null"
    style="height: fit-content !important"
    @click="onHeaderClicked">
    <h3><slot></slot></h3>
  </th>
</template>

<script>
export default {
  props: {
    centered: Boolean,
  },
  methods: {
    onHeaderClicked() {
      this.$emit("headerClicked");
    },
  },
};
</script>
