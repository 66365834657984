<template>
  <v-dialog v-model="visible" width="600" persistent>
    <v-card elevation="160">
      <v-card-title
        class="corporate-dark-bg py-4 px-6 d-flex justify-space-between">
        <div class="d-flex">
          <v-icon size="30" class="" style="color: red" right>
            {{ $icons.Connection }}
          </v-icon>
          <span class="ml-2" style="color: white">
            {{ title }}
          </span>
        </div>
      </v-card-title>
      <v-card-text class="mt-3 pb-0">
        <div class="text-main mt-3">
          {{ text }}
        </div>
      </v-card-text>
      <v-card-actions class="d-flex px-6 pb-4 justify-end">
        <StandardButton
          :buttonText="'I understand'"
          @onClick="
            () => {
              hide();
            }
          "
          small
          :width="100"
          classname="primary-btn" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defaultConnOptions } from "@/plugins/connectionCheck/connectionChecker";
import StandardButton from "@/components/buttons/StandardButton";

export default {
  components: { StandardButton },
  data() {
    return {
      visible: false,
      title: "Unstable connection detected!",
      text: "An unstable connection may lead the platform to display outdated or wrong data, and an overall worse user experience. Please keep this in mind if you are planning to perform critical actions during this session.",
    };
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  },
  mounted() {
    document.addEventListener(
      defaultConnOptions.unstableConnectionEvent,
      (event) => {
        this.show();
      }
    );
  },
};
</script>