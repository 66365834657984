import Vue from "vue";
import VueRouter from "vue-router";
import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import { ROUTENAMES } from "./routeNames";

const AppRecovery = import("@/views/auth/Recovery/AppRecovery");
const Recovery = import("@/views/auth/Recovery/Recovery");
const Validation = import("@/views/auth/Register/Validation");
const RecoverySuccess = import("@/views/auth/Recovery/RecoverySuccess");
const Login = import("@/views/auth/Login/Login");
const Callback = import("@/views/auth/Login/Callback");
const VerifyPhone = import("@/views/auth/Login/VerifyPhone");
const Dashboard = import("@/views/admin/Dashboard");

const Metrics = import("@/views/admin/metrics/Index");

const Applications = import("@/views/admin/services/Index");
const ServiceDetail = import("@/views/admin/services/Detail");

const Users = import("@/views/admin/users/Index");
const UsersDetail = import("@/views/admin/users/Detail");

const Keys = import("@/views/admin/keys/Index");
const Devices = import("@/views/admin/devices/Index");
const keyGroups = import("@/views/admin/keyGroups/Index");
const Groups = import("@/views/admin/groups/Index");
const Settings = import("@/views/admin/settings/Settings");
const Integrations = import("@/views/admin/integrations/Index");

const Logs = import("@/views/admin/logs/Index");

const IDS = import("@/views/admin/ids/Index");
const Permissions = import("@/views/admin/policies/Index");

const GetStarted = import("@/views/admin/getStarted/Index");

const Licenses = import("@/views/admin/licenses/Index");

const Plugins = import("@/views/admin/plugins/Index");

const WantItPage = import("@/views/auth/Register/WantItPage");
const EmailVerification = import("@/views/auth/Confirm/EmailConfirmation");
const PaymentSuccess = import("@/views/auth/Payment/PaymentSuccess");
const PaymentCancelled = import("@/views/auth/Payment/PaymentCancelled");
const TimeOutPage = import("@/views/error/TimeOutPage");
const ErrorPage = import("@/views/error/ErrorPage");

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: ROUTENAMES.LOGIN,
    component: () => Login,
  },
  {
    path: "/callback/auth/:companyId/:idp/callback",
    name: ROUTENAMES.CALLBACK,
    component: () => Callback,
  },
  {
    path: "/recovery-success",
    name: ROUTENAMES.RECOVERYSUCCESS,
    component: () => RecoverySuccess,
  },
  {
    path: "/recovery",
    name: ROUTENAMES.RECOVERY,
    component: () => Recovery,
  },
  {
    path: "/app-recovery",
    name: ROUTENAMES.APPRECOVERY,
    component: () => AppRecovery,
  },
  {
    path: "/verify",
    name: ROUTENAMES.VERIFY,
    component: () => VerifyPhone,
  },
  {
    path: "/email-verification/auth/:companyId/:accessCondition/callback",
    name: ROUTENAMES.EMAILVERIFICATION,
    component: () => EmailVerification,
  },
  {
    path: "/validation",
    name: ROUTENAMES.VALIDATION,
    component: () => Validation,
  },
  {
    path: "/payment-success",
    name: ROUTENAMES.PAYMENTSUCCESS,
    component: () => PaymentSuccess,
  },
  {
    path: "/payment-cancelled",
    name: ROUTENAMES.PAYMENTCANCELLED,
    component: () => PaymentCancelled,
  },
  {
    path: "/admin",
    component: () => Dashboard,
    children: [
      {
        name: ROUTENAMES.GETSTARTED,
        path: "/",
        component: () => GetStarted,
      },
      {
        name: ROUTENAMES.METRICS,
        path: "metrics",
        component: () => Metrics,
      },
      {
        name: ROUTENAMES.LICENSES,
        path: "licenses",
        component: () => Licenses,
      },
      {
        name: ROUTENAMES.APPLICATIONS,
        path: "applications",
        component: () => Applications,
      },
      {
        name: ROUTENAMES.SERVICEDETAIL,
        path: "applications/:id",
        props: true,
        component: () => ServiceDetail,
      },
      {
        name: ROUTENAMES.USERS,
        path: "users",
        component: () => Users,
      },
      {
        name: ROUTENAMES.USERDETAIL,
        path: "users/:id",
        props: true,
        component: () => UsersDetail,
      },
      {
        name: ROUTENAMES.KEYS,
        path: "keys",
        component: () => Keys,
      },
      {
        name: ROUTENAMES.DEVICES,
        path: "devices",
        component: () => Devices,
      },
      {
        name: ROUTENAMES.KEYGROUPS,
        path: "keyGroups",
        component: () => keyGroups,
      },
      {
        name: ROUTENAMES.GROUPS,
        path: "groups",
        component: () => Groups,
      },
      {
        name: ROUTENAMES.IDS,
        path: "ids",
        component: () => IDS,
      },
      {
        name: ROUTENAMES.PERMISSIONS,
        path: "permissions",
        component: () => Permissions,
      },
      {
        name: ROUTENAMES.LOGS,
        path: "logs",
        component: () => Logs,
      },
      {
        name: ROUTENAMES.SETTINGS,
        path: "mfa",
        component: () => Settings,
      },
      {
        name: ROUTENAMES.PLUGINS,
        path: "plugins",
        component: () => Plugins,
      },
      {
        name: ROUTENAMES.INTEGRATIONS,
        path: "sync",
        component: () => Integrations,
      },
    ],
  },
  {
    path: "/want-it",
    name: ROUTENAMES.WANTIT,
    component: () => WantItPage,
  },
  {
    path: "/time-out",
    name: ROUTENAMES.TIMEOUTLANDINGPAGE,
    component: () => TimeOutPage,
  },
  {
    path: "/error",
    name: ROUTENAMES.WANTITERROR,
    component: () => ErrorPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Ironchip | " + (to.name ? to.name : "Authenticator");
  if (!to.matched.length) {
    next({ name: ROUTENAMES.LOGIN });
  }
  if (
    to.name === ROUTENAMES.WANTITERROR ||
    to.name === ROUTENAMES.TIMEOUTLANDINGPAGE ||
    to.name === ROUTENAMES.WANTIT ||
    to.name === ROUTENAMES.APPDOWNLOAD ||
    to.name === ROUTENAMES.LOGIN ||
    to.name === ROUTENAMES.CALLBACK ||
    to.name === ROUTENAMES.VERIFY ||
    to.name === ROUTENAMES.EMAILVERIFICATION ||
    to.name === ROUTENAMES.RECOVERYSUCCESS ||
    to.name === ROUTENAMES.RECOVERY ||
    to.name === ROUTENAMES.APPRECOVERY ||
    to.name === ROUTENAMES.VALIDATION ||
    to.name === ROUTENAMES.PAYMENTSUCCESS ||
    to.name === ROUTENAMES.PAYMENTCANCELLED
  ) {
    next();
  } else {
    ironchipsdk
      .HEADMeCompanyTokenValidation()
      .then((response) => {
        next();
      })
      .catch(() => {
        next({ name: ROUTENAMES.LOGIN });
      });
  }
});

export default router;
