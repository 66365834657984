export default class SearchTokenModel {
  constructor(
    options = { name: null, queryValue: null, selectedValue: null, options: [] }
  ) {
    this.name = options.name;
    this.queryValue = options.queryValue;
    this.selectedValue = options.selectedValue || null;
    this.options = options.options;
  }
}
