import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import { SECONDS } from "./timeService.js";
import LicenseModel from "@/models/licenseModel";
import BillingDetailsModel from "@/models/billingDetailsModel";
import InvoiceModel from "@/models/invoiceModel";

export const LICENSE_EVENTS = {
  BILLING_DETAILS_CHANGED: "billingDetailsChanged",
};

export default class LicensesService {
  constructor(notificationService, timeService) {
    this.currentPlan = {};
    this.billingDetails = {};
    this.invoices = new Map();
    this.canReadLicense = false;
    this._initializeActiveLicense();
    this._initializeBillingDetails();
    this._initializeCompanyInvoices();
    this._initializeBillingDetailsUpdatedHandler(notificationService);
    this.billingDetailsChangedEvent = new CustomEvent(
      LICENSE_EVENTS.BILLING_DETAILS_CHANGED
    );
    this.timeService = timeService;
  }

  _initializeActiveLicense() {
    return ironchipsdk
      .GETMeCompanyActiveLicense()
      .then((response) => {
        this.currentPlan = new LicenseModel(response);
        this.canReadLicense = true;
      })
      .catch((error) => {
        if (error.response.status == "404") {
          console.error("No active license found");
          this.canReadLicense = true;
        }
      });
  }

  _initializeBillingDetails() {
    return ironchipsdk
      .GETMeCompanyBillingDetails()
      .then((response) => {
        this.billingDetails = new BillingDetailsModel(response);
      })
      .catch((error) => {
        if (error.response.status == "404") {
          this.billingDetails = new BillingDetailsModel({});
          console.error("No billing details found");
        }
      });
  }

  _initializeCompanyInvoices() {
    return ironchipsdk
      .GETMeCompanyBillingInvoices()
      .then((response) => {
        response.forEach((invoice) => {
          this.invoices.set(invoice._id, new InvoiceModel(invoice));
        });
      })
      .catch((error) => {
        if (error.response.status == "404") {
          console.error("No invoices found");
        }
      });
  }

  _onBillingDetailsUpdated(notification) {
    let updatedData = notification.data;

    this.billingDetails.billingInterval = updatedData.billing_frequency;
    this.billingDetails.billingEmail = updatedData.billing_email;
    this.billingDetails.billingAddress = updatedData.billing_address;
    this.billingDetails.billingDetails.VATNumber =
      updatedData.company_details.company_cif;
    window.dispatchEvent(this.billingDetailsChangedEvent);
  }

  _initializeBillingDetailsUpdatedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.BILLING_DETAILS_UPDATED,
      function (notification) {
        this._onBillingDetailsUpdated(notification);
      }.bind(this),
      null
    );
  }

  updateBillingDetails(billingDetails) {
    return ironchipsdk
      .PUTMeCompanyBillingDetails(billingDetails)
      .then((response) => {})
      .catch((error) => {
        throw error;
      });
  }

  getActiveLicense() {
    return this.currentPlan;
  }

  getBillingDetails() {
    return this.billingDetails;
  }

  getInvoices() {
    return Array.from(this.invoices.values());
  }

  getUsageDaysLeft() {
    const CURRENTMICROS = this.timeService.currentMicrosFromEpoch;
    const REMAINING_TIME_MICROS =
      this.currentPlan.planPeriod.end - CURRENTMICROS;
    const MICROS_IN_A_DAY = SECONDS.day * 1000000000;

    return Math.floor(REMAINING_TIME_MICROS / MICROS_IN_A_DAY);
  }
}
