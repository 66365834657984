export const SESSION_COOKIE_NAME = "tempSession";

export function setCookie(name, value, days = 1000, path = "/") {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    path +
    "; secure";
}

export function getCookie(name) {
  const cookie = document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key.trim()]: decodeURIComponent(value),
    }));
  return cookie[name];
}

export function deleteCookie(name, path = "/") {
  setCookie(name, "", -1, path);
}
