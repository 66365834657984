import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import errorsService from "./errorsService";

export default class MetricsService {
  constructor() {}

  async getCompanyMetric(metric, tsStart, tsEnd, step) {
    return await ironchipsdk
      .GETMeCompanyMetric(metric, tsStart, tsEnd, step)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async getUserMetric(userID, metric, tsStart, tsEnd, step) {
    return await ironchipsdk
      .GETMeCompanyUserMetric(userID, metric, tsStart, tsEnd, step)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async getServiceMetric(serviceID, metric, tsStart, tsEnd, step) {
    return await ironchipsdk
      .GETMeCompanyServiceMetric(serviceID, metric, tsStart, tsEnd, step)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async getAccessMetric(serviceID, userID, metric, tsStart, tsEnd, step) {
    return await ironchipsdk
      .GETMeCompanyAccessMetric(serviceID, userID, metric, tsStart, tsEnd, step)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }
  async getUsersApplicationCountMetric(userID, tsStart, tsEnd) {
    return await ironchipsdk
      .GETMeCompanyUsersApplicationCountMetric(userID, tsStart, tsEnd)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }
}
