import {
  ironchipsdk,
  ORDER_UNORDERED,
  ORDER_ASC,
  ORDER_DESC,
} from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import KeyGroupModel from "../models/keyGroupModel";
import errorsService from "./errorsService";

export const KEY_GROUPS_PER_PAGE = 8,
  DEFAULT_KEY_GROUPS = ["Any user devices", "Any user safezones", "User email"],
  UNORDERED = ORDER_UNORDERED,
  ASC = ORDER_ASC,
  DESC = ORDER_DESC,
  KEY_GROUP_CHANGE_EVENT = "keyGroupsChanged";

export default class KeyGroupsService {
  constructor(notificationService) {
    this.keyGroups = new Map();

    this.keyGroupsChangeEvent = new CustomEvent(KEY_GROUP_CHANGE_EVENT);

    this._initializeKeyGroup();
    this._initializeKeyGroupAddedHandler(notificationService);
    this._initializeKeyGroupUpdatedHandler(notificationService);
    this._initializeKeyGroupDeletedHandler(notificationService);
  }

  async _fetchKeyGroups() {
    await ironchipsdk
      .GETMeCompanyKeyTags()
      .then((keyGroups) => {
        let temporalKeyGroups = new Map();
        keyGroups.forEach((keyGroup) => {
          temporalKeyGroups.set(
            keyGroup.id,
            KeyGroupModel.JSONToModel(keyGroup)
          );
        });
        this.keyGroups = temporalKeyGroups;
      })
      .catch((err) => {
        this.keyGroups = new Map();
        console.error(err);
      });
  }

  async addKeyGroup(keyGroup, credentials = {}) {
    return await ironchipsdk
      .POSTMeCompanyKeyTags(keyGroup, credentials)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async addKeysToKeyGroup(keyGroupId, data, credentials = {}) {
    return await ironchipsdk
      .PUTMeCompanyKeyTags(keyGroupId, data, credentials)
      .then((resp) => {
        window.dispatchEvent(this.keyGroupsChangeEvent);
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteKeyGroup(keyGroupId) {
    return await ironchipsdk
      .DELETEMeCompanyKeyTag(keyGroupId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  getAllKeyGroups() {
    return Array.from(this.keyGroups.values());
  }

  getKeyGroups(page, limit = KEY_GROUPS_PER_PAGE, filter = "") {
    let list = Array.from(this.keyGroups.values());
    if (filter != "") {
      list = list.filter((keyGroup) => {
        return keyGroup.name.toLowerCase().includes(filter.toLowerCase());
      });
    }

    if (page == -1) return list;

    let skip = page - 1;
    let start = skip * limit;
    let end = start + limit > this.keyGroups.length ? -1 : start + limit;

    return list.slice(start, end);
  }

  getKeyGroupById(keyGroupId) {
    return this.keyGroups.get(keyGroupId);
  }
  getKeyGroupByName(keyGroupName) {
    return Array.from(this.keyGroups.values()).find(
      (ele) => ele.name == keyGroupName
    );
  }

  getKeyGroupsPageCount(limit = KEY_GROUPS_PER_PAGE, filter = "") {
    if (this.keyGroups.size <= 0) return 100;
    if (filter == "") return Math.ceil(this.keyGroups.size / limit);

    let list = Array.from(this.keyGroups.values());
    list = list.filter((keyGroup) => {
      return keyGroup.name.toLowerCase().includes(filter.toLowerCase());
    });
    return Math.ceil(list.length / limit);
  }

  async testLDAPCredentials(keyGroupId, credentials) {
    return ironchipsdk.POSTMeCompanyKeyGroupTestById(keyGroupId, credentials);
  }

  async _initializeKeyGroup() {
    await this._fetchKeyGroups();
  }

  _initializeKeyGroupAddedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_TAG_ADDED,
      function (notification) {
        this._onKeyGroupAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeKeyGroupUpdatedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_TAG_UPDATED,
      function (notification) {
        this._onKeyUpdated(notification);
      }.bind(this),
      null
    );
  }

  _initializeKeyGroupDeletedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_TAG_DELETED,
      function (notification) {
        this._onKeyDeleted(notification);
      }.bind(this),
      null
    );
  }

  async _onKeyGroupAdded(key) {
    await this._fetchKeyGroups();
    // WE ARE MISSING MISSING EVENT FROM BACK END TO REFRESH THE GROUPS ON CERT UPDATE
    window.dispatchEvent(
      new CustomEvent(KEY_GROUP_CHANGE_EVENT, {
        detail: { name: key.data.name, id: key.data.id },
      })
    );
  }

  async _onKeyUpdated(key) {
    await this._fetchKeyGroups();
    window.dispatchEvent(this.keyGroupsChangeEvent);
  }

  async _onKeyDeleted(key) {
    await this._fetchKeyGroups();

    window.dispatchEvent(this.keyGroupsChangeEvent);
  }
}
