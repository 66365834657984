import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";

window.ironchipsdk = ironchipsdk;

ironchipsdk.Init(config.VUE_APP_API_HOST, "1.1.5");

export default {
  install(Vue, options) {
    Vue.prototype.$sdk = ironchipsdk;
  },
  ironchipsdk,
};
