export const defaultConnOptions = {
  maxAllowedLatency: 2800, //millis
  unstableConnectionEvent: "unstableConnection",
}

const unstableConnectionEvent = new CustomEvent(defaultConnOptions.unstableConnectionEvent);

export default {
  install(Vue, options) {
    this.connection = window.navigator.connection;
    this._setConnectionChangeListener();
    Vue.prototype.$getClientLatency = () => {
      return this.connection.rtt;
    }
  },

  _setConnectionChangeListener() {
    this.connection.addEventListener('change', this._connectionChangeHandler.bind(this))
  },

  _connectionChangeHandler() {
    if (this.getLatency() >= defaultConnOptions.maxAllowedLatency) {
      document.dispatchEvent(unstableConnectionEvent);
    }
  },

  getLatency() {
    return this.connection.rtt;
  },
};