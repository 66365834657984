import AccessModel from "../models/accessModel";
import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import { ACCESS_ADDED, ACCESS_DELETED, ACCESS_UPDATED } from "./eventsService";
import errorsService from "./errorsService";

const ACCESSES_SERVICE_INITIALIZATION_ERROR = new Error(
  "Error initializing accesses service"
);

export const ACCESSES_PER_SERVICE = 6,
  ACCESSES_PER_PAGE = 10;

export default class AccessesService {
  constructor(eventsService, notificationService) {
    this.accesses = new Map();
    this.accessChangeEvent = new CustomEvent("accessChanged");
    this.massiveReportAddedEvent = "massiveReportAdded";
    this.massiveReportUpdatedEvent = "massiveReportUpdated";
    this.massiveReportState = {};
    this._initializeAccessAddedHandler(eventsService, notificationService);
    this._initializeAccessUpdatedHandler(eventsService, notificationService);
    this._initializeAccessDeletedHandler(eventsService, notificationService);
  }

  getCompanyAccesses() {
    return Array.from(this.accesses.values());
  }

  async getAccessesOfUser(userID, countServices = false) {
    return await ironchipsdk
      .GETMeCompanyUserAccessesByID(userID)
      .then((resp) => {
        let userAccesses = [];
        let services = new Map();
        resp.forEach((access) => {
          if (
            access.service_external_name == "Ironchip Authenticator" ||
            access.service_external_name == "recovery"
          ) {
            return;
          }

          if (countServices && !services.has(access.service_id))
            services.set(access.service_id, access.group_name);

          userAccesses.push(
            new AccessModel(
              access.id,
              access.group_name,
              access.external_username_layout,
              access.external_username_layout_label,
              access.service_external_name,
              access.group_id,
              access.service_id,
              access.company_id,
              access.optional_required_key_tags
            )
          );
        });
        let response = { user_accesses: userAccesses };

        if (countServices) response.services_count = services.size;

        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  async getUserHasRecovery(userID) {
    return await ironchipsdk
      .GETMeCompanyUserAccessesByID(userID)
      .then((resp) => {
        return resp.find(
          (element) => element.service_external_name == "recovery"
        );
      })
      .catch((err) => {
        throw err;
      });
  }

  async getAccessesOfServiceSearchResultLength(serviceID, filter) {
    return await ironchipsdk
      .GETMeCompanyServiceAccessesLength(serviceID, filter)
      .catch((err) => {
        return 0;
      });
  }

  async getAccessesOfService(serviceID, limit = "", skip = "", filter = "") {
    if (!serviceID) return;
    return await ironchipsdk
      .GETMeCompanyServiceAccessesByID(serviceID, limit, skip, filter)
      .then((resp) => {
        let serviceAccesses = [];
        resp.forEach((access) => {
          serviceAccesses.push(
            new AccessModel(
              access.id,
              access.group_name,
              access.external_username_layout,
              access.external_username_layout_label,
              access.service_external_name,
              access.group_id,
              access.service_id,
              access.company_id,
              access.optional_required_key_tags
            )
          );
        });
        return serviceAccesses;
      })
      .catch((err) => {
        throw err;
      });
  }

  async addAccess(access) {
    const accessAdded = {
      external_username_layout: access.external_username_layout,
      external_username_layout_label: access.external_username_layout_label,
      service_external_name: access.service_external_name,
      group_id: access.group_id,
      service_id: access.service_id,
      company_id: access.company_id,
      optional_required_key_tags: access.optional_required_key_tags,
    };
    return await ironchipsdk
      .POSTMeCompanyAccesses(accessAdded)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteAccess(accessID) {
    return await ironchipsdk
      .DELETEMeCompanyAccessByID(accessID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async updateAccess(access) {
    return await ironchipsdk
      .PUTMeCompanyAccessByID(access)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async addRecoveryDevice(userId, deviceId) {
    return await ironchipsdk
      .POSTMeCompanyUserRecoveryDOByID(userId, deviceId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async getKeyGroupRelatedAccesses(keyGroupID) {
    return ironchipsdk.GETMeCompanyAccessesByKeyGroupID(keyGroupID);
  }

  _initializeAccessAddedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ACCESS_ADDED,
      function (notification) {
        this._onAccessAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeAccessUpdatedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ACCESS_UPDATED,
      function (notification) {
        this._onAccessUpdated(notification);
      }.bind(this),
      null
    );
  }

  _initializeAccessDeletedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ACCESS_DELETED,
      function (notification) {
        this._onAccessDeleted(notification);
      }.bind(this),
      null
    );
  }

  _onAccessAdded(access) {
    window.dispatchEvent(this.accessChangeEvent);
  }

  _onAccessUpdated(access) {
    window.dispatchEvent(this.accessChangeEvent);
  }

  _onAccessDeleted(access) {
    window.dispatchEvent(this.accessChangeEvent);
  }
}
