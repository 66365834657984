<template>
  <td name="cell" :class="centered ? 'text-center justify-center' : null">
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    centered: Boolean,
  },
};
</script>
