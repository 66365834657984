import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import TransactionReportModel from "@/models/transactionReportModel";

export const REPORTS_PER_PAGE = 10;

export default class IdsService {
  constructor() {
    this.reports = new Map();
    this.logs = new Map();
    this.idsReportChangeEvent = new CustomEvent("idsReportChanged");
    this.idsLogChangeEvent = new CustomEvent("idsLogChanged");
    this.hasIDSClearance = false;
  }

  getReportURL(reportId) {
    return ironchipsdk.GETMeCompanyIDSReportURLByID(reportId);
  }

  async getReports(limit, page) {
    this.reports = new Map();

    return await ironchipsdk
      .GETIDSReports(limit, page)
      .then((reports) => {
        this.hasIDSClearance = true;
        reports.forEach((report) =>
          this.reports.set(
            report.transaction_id,
            new TransactionReportModel(report)
          )
        );
        return Array.from(this.reports.values());
      })
      .catch((error) => {
        if (error.response.status === 402) {
          this.hasIDSClearance = false;
        }
      });
  }

  getFilteredReports(limit, skip, filter) {
    //TODO: IMPLEMENT SDK METHOD
    ironchipsdk.GETIDSReports(limit, skip, filter).then((reports) => {
      reports.forEach((report) => {
        this.reports.set(report.id, report);
      });
    });
  }

  getReportCount(filter) {
    //TODO: IMPLEMENT SDK METHOD
    ironchipsdk.GETIDSReportCount(filter).then((response) => {
      return response;
    });
  }

  getFilteredLogs(limit, skip, filter, level) {
    //TODO: IMPLEMENT SDK METHOD
    ironchipsdk.GETIDSLogs(limit, skip, filter, level).then((logs) => {
      logs.forEach((log) => {
        this.logs.set(log.id, log);
      });
    });
  }

  getLogCount(filter) {
    //TODO: IMPLEMENT SDK METHOD
    ironchipsdk.GETIDSLogCount(filter).then((response) => {
      return response;
    });
  }

  async updateReports(limit, level) {
    //TODO: CLEAN UP (REMOVE)
    this.reports = new Map();

    const source = ironchipsdk.establishIDSEventSourcePolyfill(limit, level);
    source.onopen = (event) => {
      if (event.status == 200) this.hasIDSClearance = true;
    };
    source.onerror = (event) => {
      if (event.status == 403) this.hasIDSClearance = false;
    };

    source.addEventListener(
      "event",
      async function (e) {
        const parsedEvent = JSON.parse(e.data);
        this.reports.set(parsedEvent.transaction_id, parsedEvent);
        window.dispatchEvent(this.idsReportChangeEvent);
      }.bind(this),
      false
    );
  }

  getIDSClearance() {
    return this.hasIDSClearance;
  }

  getLogs() {
    return Array.from(this.logs.values());
  }

  async updateLogs(limit, level) {
    this.logs = new Map();

    const source = ironchipsdk.establishIDSLogEventSourcePolyfill(limit, level);
    source.onopen = (event) => {
      if (event.status == 200) this.hasIDSClearance = true;
    };
    source.onerror = (event) => {
      if (event.status == 403) this.hasIDSClearance = false;
    };

    source.addEventListener(
      "event",
      async function (e) {
        const parsedEvent = JSON.parse(e.data);
        this.logs.set(parsedEvent.transaction_id, parsedEvent);
        window.dispatchEvent(this.idsLogChangeEvent);
      }.bind(this),
      false
    );
  }
}
