<template>
  <div id="app">
    <v-app>
      <v-main>
        <router-view />
        <v-dialog v-model="error" width="600" persistent>
          <ErrorAlert
            @continue="logout"
            title="Critical error."
            :subtitle="errorDetail" />
        </v-dialog>
      </v-main>
      <Footer id="footer" />
      <UnstableConnectionAlert />
    </v-app>
  </div>
</template>
<script>
import Footer from "./components/common/Footer";
import { logout } from "@/utils/userManagement";
import ErrorAlert from "@/components/alerts/ErrorAlert";
import UnstableConnectionAlert from "@/components/alerts/UnstableConnectionModal";

export default {
  components: {
    Footer,
    ErrorAlert,
    UnstableConnectionAlert,
  },

  data() {
    return {
      error: false,
      errorDetail: "",
    };
  },

  mounted() {
    const self = this;
    window.addEventListener("serviceInitError", function (error) {
      self.errorDetail = error.detail + ". Please, try again shortly.";
      self.error = true;
    });
  },

  methods: {
    logout() {
      this.error = false;
      logout();
    },
  },
};
</script>
