const CLIENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const DATEFORMAT = {
  TimelineHeaders: {
    month: "long",
    day: "numeric",
    weekday: "long",
    timeZone: CLIENT_TIMEZONE,
  },
  Daytime: {
    timeStyle: "medium",
    hour12: false,
  },
  MonthAndYear: {
    year: "numeric",
    month: "long",
    timeZone: CLIENT_TIMEZONE,
  },
  SyncReport: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: CLIENT_TIMEZONE,
  },
  CertificateName: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: CLIENT_TIMEZONE,
  },
  Invoice: {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: CLIENT_TIMEZONE,
  },
};

export const SECONDS = {
  day: 60 * 60 * 24,
};

export const LOCALES = {
  US: "en-us",
};

export const TIMEZONES = {
  DEFAULT: CLIENT_TIMEZONE,
  SPAIN: "Europe/Madrid",
};

export const FORMAT = {
  SECS: "SECONDS",
  MILLIS: "MILLISECONDS",
  MICROS: "MICROSECONDS",
  UNKNOWN: "UNKNOWN TIMESTAMP FORMAT: $length digits.",
};

export const CONVERT = {
  nanosToSeconds: (nanos) => nanos / 1000000000,
};

export default class TimeService {
  constructor() {}

  get currentSecondsFromEpoch() {
    return this.millisToSeconds(this.currentMillisFromEpoch);
  }

  get currentMillisFromEpoch() {
    return Date.now();
  }

  get currentMicrosFromEpoch() {
    return this.currentMillisFromEpoch * 1000000;
  }

  get startOfTodayInMicros() {
    return new Date().setUTCHours(-1, 0, 0, 0) * 1000000;
  }

  get endOfTodayInMillis() {
    return new Date().setHours(23, 59, 59, 999);
  }

  get startOfCurrentMonthInMillis() {
    const currentDate = new Date();
    const firstDayOfMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    return firstDayOfMonthDate.getTime();
  }

  get endOfCurrentMonthInMillis() {
    const currentDate = new Date();
    const lastDayOfMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    lastDayOfMonthDate.setMilliseconds(-1);
    return lastDayOfMonthDate.getTime();
  }

  millisToSeconds(millis) {
    return Math.floor(millis / 1000);
  }

  nanosToMillis(nanos) {
    return Math.floor(nanos / 1000000);
  }

  secondsToNanos(seconds) {
    return seconds * 1000000;
  }

  nanosToSeconds(nanos) {
    return nanos / 1000000000;
  }

  ISOToFormattedLocale(ISOString, locale, formatOptions) {
    // ISO 8601 Extended format
    // YYYY-MM-DDTHH:mm:ss.sssZ
    return new Date(ISOString).toLocaleDateString(locale, formatOptions);
  }

  secondsFromEpochToISOStartOfDay(secondsFromEpoch) {
    // ISO 8601 Extended format
    // YYYY-MM-DDTHH:mm:ss.sssZ
    const epoch = new Date(1970, 0, 1);
    epoch.setSeconds(secondsFromEpoch);
    epoch.setUTCHours(1, 0, 0, 0);
    return epoch.toISOString();
  }

  secondsFromEpochToLocalDayTime(secondsFromEpoch, locale, formatOptions) {
    const epoch = new Date(1970, 0, 1);
    epoch.setUTCSeconds(
      secondsFromEpoch - (epoch.getTimezoneOffset() / 60) * 3600
    );
    return epoch.toLocaleTimeString(locale, formatOptions);
  }

  getElapsedTimeFromSeconds(secondsFromEpoch) {
    const epoch = new Date(1970, 0, 1);
    epoch.setUTCSeconds(
      secondsFromEpoch - (epoch.getTimezoneOffset() / 60) * 3600
    );

    const now = new Date(1970, 0, 1);
    now.setUTCSeconds(
      this.currentSecondsFromEpoch - (now.getTimezoneOffset() / 60) * 3600
    );

    let diff = Math.abs(now - epoch) / 1000;
    //SECONDS
    return diff;
  }

  getMonthNumberFromISO(ISOString) {
    return new Date(ISOString).getMonth() + 1;
  }

  getStartOfMonthInMillisFromISO(ISOString) {
    const targetDate = new Date(this.transformToSTDFormat(ISOString));
    const firstDayOfMonthDate = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      1
    );
    return firstDayOfMonthDate.getTime();
  }

  getEndOfMonthInMillisFromISO(ISOString) {
    const targetDate = new Date(this.transformToSTDFormat(ISOString));
    const lastDayOfMonthDate = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth() + 1,
      1
    );
    lastDayOfMonthDate.setMilliseconds(-1);
    return lastDayOfMonthDate.getTime();
  }

  transformToSTDFormat(ISOString) {
    //TRANSFORMS TO VALID CHROME & FIREFOX FORMAT (YYYY-MM)
    return `${ISOString.split(" ")[1]}-${this.getMonthNumberFromISO(
      ISOString
    )}`;
  }

  getMonthNumberFromISO(ISOString) {
    const monthName = ISOString.split(" ")[0];
    switch (monthName) {
      case "January":
        return "01";

      case "February":
        return "02";

      case "March":
        return "03";

      case "April":
        return "04";

      case "May":
        return "05";

      case "June":
        return "06";

      case "July":
        return "07";

      case "August":
        return "08";

      case "September":
        return "09";

      case "October":
        return "10";

      case "November":
        return "11";

      case "December":
        return "12";
    }
  }

  getFormattedDateTimeFromMilliseconds(
    millisecondsFromEpoch,
    locale,
    formatOptions
  ) {
    return new Date(millisecondsFromEpoch).toLocaleDateString(
      locale,
      formatOptions
    );
  }

  getTimestampFormat(timestamp) {
    const timestampLength = Math.floor(timestamp).toString().length;
    switch (timestampLength) {
      case 10:
        return FORMAT.SECS;
      case 13:
        return FORMAT.MILLIS;
      case 16:
        return FORMAT.MICROS;
      default:
        return FORMAT.UNKNOWN.replace("$length", timestampLength);
    }
  }
}
