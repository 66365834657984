import ServicesService from "./servicesService";
import AccessesService from "./accessesService";
import KeysService from "./keysService";
import KeyGroupsService from "./keyGroupsService";
import GroupsService from "./groupsService";
import UsersService from "./usersService";
import CompanyService from "./companyService";
import MetricsService from "./metricsService";
import IntegrationsService from "./integrationsService";
import EventsService from "./eventsService";
import AuthorizationEventService from "./authorizationEventService";
import errorsService from "./errorsService";
import IdsService from "./idsService";
import LicensesService from "./licensesService";
import ViewportService from "./viewportService";
import NotificationService from "./notificationService";
import PoliciesService from "./policiesService";
import TimeService from "./timeService";

export default {
  async install(Vue, options) {
    let eventsServiceInstance;
    let notificationServiceInstance;
    let timeServiceInstance;
    try {
      eventsServiceInstance = new EventsService();
      notificationServiceInstance = new NotificationService();
      timeServiceInstance = new TimeService();

      // let metricService = new MetricsService()
      // let companyService = new CompanyService(eventsServiceInstance)
      let companyServicesServiceInstance = new ServicesService(
        eventsServiceInstance,
        notificationServiceInstance
      );
      // let integrationService = new IntegrationsService(eventsServiceInstance)
      let accessesService = new AccessesService(
        eventsServiceInstance,
        notificationServiceInstance
      );
      let usersServiceInstance = new UsersService(
        eventsServiceInstance,
        accessesService,
        notificationServiceInstance
      );
      let keysServiceInstance = new KeysService(
        eventsServiceInstance,
        notificationServiceInstance
      );
      let keyGroupsServiceInstance = new KeyGroupsService(
        notificationServiceInstance
      );
      let groupsServiceInstance = new GroupsService(
        eventsServiceInstance,
        accessesService,
        notificationServiceInstance
      );
      let viewportServiceInstance = new ViewportService();
      let idsServiceInstance = new IdsService();
      // let authorizationService = new AuthorizationEventService(eventsServiceInstance, companyServicesServiceInstance, usersServiceInstance, keysServiceInstance)

      Vue.prototype.$eventsService = eventsServiceInstance;
      Vue.prototype.$servicesService = companyServicesServiceInstance;
      Vue.prototype.$accessesService = accessesService;
      Vue.prototype.$keysService = keysServiceInstance;
      //FIXTAG
      Vue.prototype.$keyGroupsService = keyGroupsServiceInstance;
      Vue.prototype.$groupsService = groupsServiceInstance;
      Vue.prototype.$usersService = usersServiceInstance;
      Vue.prototype.$viewportService = viewportServiceInstance;
      Vue.prototype.$authorizationEventService = new AuthorizationEventService(
        eventsServiceInstance,
        companyServicesServiceInstance,
        usersServiceInstance,
        keysServiceInstance,
        timeServiceInstance,
        notificationServiceInstance
      );
      Vue.prototype.$companyService = new CompanyService(
        eventsServiceInstance,
        notificationServiceInstance,
        timeServiceInstance
      );
      Vue.prototype.$metricsService = new MetricsService();
      Vue.prototype.$integrationsService = new IntegrationsService(
        eventsServiceInstance,
        notificationServiceInstance
      );
      Vue.prototype.$licensesService = new LicensesService(
        notificationServiceInstance,
        timeServiceInstance
      );
      Vue.prototype.$policiesService = new PoliciesService(
        notificationServiceInstance
      );
      Vue.prototype.$idsServiceInstance = idsServiceInstance;
      Vue.prototype.$notificationService = notificationServiceInstance;
      Vue.prototype.$timeService = timeServiceInstance;
      Vue.prototype.$errorsService = errorsService;
    } catch (error) {
      // TODO users constructor error doesn't get here
      let serviceInitError = new CustomEvent("serviceInitError", {
        detail: error.message,
      });
      window.dispatchEvent(serviceInitError);
    }
  },
  ServicesService,
  AccessesService,
  KeysService,
  KeyGroupsService,
  GroupsService,
  UsersService,
  CompanyService,
  MetricsService,
  IntegrationsService,
  EventsService,
  AuthorizationEventService,
  errorsService,
  LicensesService,
  PoliciesService,
  IdsService,
  NotificationService,
  TimeService,
};
