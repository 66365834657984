<template>
  <div>
    <v-btn
      depressed
      text
      :type="type"
      :disabled="disabled"
      :small="small"
      :large="large"
      :rounded="rounded"
      :height="height"
      :width="!ignoreWidth ? width + 'px' : null"
      :class="classname"
      @click="onClickHandler">
      <v-icon
        v-if="icon"
        class="mr-2"
        dark
        :class="flipIcon ? 'flipped' : null">
        {{ icon }}
      </v-icon>
      {{ buttonText }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    type: { type: String, default: "text" },
    disabled: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    buttonText: { type: String, default: "Click!" },
    height: { type: Number, default: 35 },
    width: { type: Number, default: 160 },
    classname: { type: String, default: "primary" },
    icon: String,
    flipIcon: Boolean,
    ignoreWidth: Boolean,
  },

  methods: {
    onClickHandler() {
      this.$emit("onClick");
    },
  },
};
</script>
<style scoped>
.flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
