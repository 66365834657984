const colors = {
  0: "rgb(66, 135, 245)",
  1: "rgb(25, 180, 228)",
  2: "rgb(254, 200, 193)",
  3: "rgb(60, 167, 124)",
  4: "rgb(11, 226, 184)",
  5: "rgb(92, 115, 184)",
  6: "rgb(161, 50, 201)",
  7: "rgb(105, 96, 175)",
  8: "rgb(29, 42, 18)",
  9: "rgb(24, 140, 136)",
  a: "rgb(196, 162, 8)",
  b: "rgb(235, 98, 19)",
  c: "rgb(69, 99, 74)",
  d: "rgb(70, 66, 97)",
  e: "rgb(143, 56, 73)",
  f: "rgb(133, 30, 62)",
};

export const EXAMPLE_USER_NAME = "Example";
export const EXTERNAL_USER_NAME = "External";

export default class UserModel {
  constructor(
    id,
    name,
    surname,
    email,
    externalEmail,
    lang,
    immutable_id,
    position,
    roles,
    companyId,
    verified,
    ironchipEnabled,
    sam_account_name,
    user_principal_name,
    invited
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.externalEmail = externalEmail || null;
    this.lang = lang;
    this.immutable_id = immutable_id;
    this.position = position;
    this.roles = roles;
    this.companyId = companyId;
    this.verified = verified;
    this.ironchipEnabled = ironchipEnabled;
    this.sam_account_name = sam_account_name;
    this.user_principal_name = user_principal_name;
    this.color = this.getColor(id);
    this.invited = invited
  }

  getColor(id = null) {
    if (id !== null && id !== undefined && id !== "") {
      return colors[id.charAt(id.length - 1)] || "rgb(149,193,31)";
    }
    const colorsArray = Object.values(colors);
    return colorsArray[Math.floor(Math.random() * colorsArray.length)];
  }

  getAvatar() {
    return (
      this.name.charAt(0).toUpperCase() + this.surname.charAt(0).toUpperCase()
    );
  }
}
