export default class TransactionReport {
  constructor(report) {
    this.application_id = report.application_id
      ? report.application_id
      : "unknown";
    this.description = report.descriptive_report
      ? report.descriptive_report
      : "unknown";
    this.device = new Device(report);
    this.em_location = new EMLocation(report);
    this.event = new Event(report);
    this.extra_data = report.extra_data ? report.extra_data : "unknown";
    this.geo_location = new GEOLocation(report);
    this.ip = new IP(report);
    this.location = new Location(report);
    this.timestamp = report.event ? report.event.timestamp : 0;
    this.id = report.transaction_id ? report.transaction_id : "unknown";
    this.user = report.user ? report.user.id : "unknown";
  }
}

class Device {
  constructor(report) {
    this.id = report.device.id ? report.device.id : "unknown";
    this.model = report.device.model ? report.device.model : "unknown";
    this.integrity = new DeviceIntegrity(report.device);
    this.sensors = new DeviceSensors(report.device);
  }
}

class DeviceIntegrity {
  constructor(device) {
    this.debugged = device.integrity.debugged
      ? device.integrity.debugged
      : false;
    this.emulated = device.integrity.emulated
      ? device.integrity.emulated
      : false;
    this.rooted = device.integrity.rooted ? device.integrity.rooted : false;
    this.gps_spoofed = device.integrity.gps_spoofed
      ? device.integrity.gps_spoofed
      : false;
  }
}

class DeviceSensors {
  constructor(device) {
    this.gps = device.sensors.gps ? device.sensors.gps : false;
    this.mobile_networks = device.sensors.mobile_networks
      ? device.sensors.mobile_networks
      : false;
    this.wifi = device.sensors.wifi ? device.sensors.wifi : false;
    this.wifi_connected = device.sensors.wifi_connected
      ? device.sensors.wifi_connected
      : false;
  }
}

class Address {
  constructor(address) {
    this.city = address.city ? address.city : "Unknown";
    this.country = address.country ? address.country : "Unknown";
    this.country_code = address.country_code ? address.country_code : "Unknown";
    this.region = address.region ? address.region : "Unknown";
    this.state = address.state ? address.state : "Unknown";
    this.latitude = address.latitude ? address.latitude : 0.0;
    this.longitude = address.longitude ? address.longitude : 0.0;
  }
}

class EMLocation {
  constructor(report) {
    this.id = report.em_location.id ? report.em_location.id : "unknown";
    this.address = new Address(report.em_location.address);
  }
}

class GEOLocation {
  constructor(report) {
    this.id = report.geo_location.id ? report.geo_location.id : "unknown";
    this.address = new Address(report.geo_location.address);
  }
}

class Location {
  constructor(report) {
    this.id = report.location.id ? report.location.id : "unknown";
    this.location_type = report.location.location_type
      ? report.location.location_type
      : "unknown";
    this.address = new Address(report.location.address);
  }
}

class IP {
  constructor(report) {
    this.tor = report.ip.tor ? report.ip.tor : false;
    this.vpn = report.ip.vpn ? report.ip.vpn : false;
    this.address = new Address(report.ip.address);
  }
}

class Event {
  constructor(report) {
    this.id = report.event.id ? report.event.id : "Unknown";
    this.risk_assignment = report.event.risk_assignment
      ? report.event.risk_assignment
      : "Clear";
    this.fraud_cases = report.event.fraud_cases ? report.event.fraud_cases : [];
    this.timestamp = report.event.timestamp ? report.event.timestamp : 0;
  }
}
