import UserModel from "../models/userModel";
import SearchTokenModel from "@/models/searchTokenModel.js";
import { EXAMPLE_USER_NAME } from "../models/userModel";

import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import store from "../store";
import errorsService from "./errorsService";

const USERS_SERVICE_INITIALIZATION_ERROR = new Error(
  "Error initializing users service"
);

const IMPORT_COMPLETED = "User synchronisation completed",
  IMPORT_INCOMPLETED = "User synchronisation incompleted",
  IMPORT_IN_PROGRESS = "User synchronisation pending";

export const USERS_PER_PAGE = 7;
export const SYNC_REPORTS_PER_PAGE = 9;
export const SYNC_REPORTS_CHANGED_EVENT = new CustomEvent(
  "importReportsChanged"
);

export const USER_PROTECTION = {
  UNPROTECTED: "not invited",
  MEDIUM: "invited",
  FULL: "enrolled",
  DISABLED: "disabled"
};

let currentSkip = 0;

export default class UsersService {
  constructor(eventsService, acessesService, notificationService) {
    this.users = new Map();
    this.firstPage = new Map();
    this.serviceUsers = new Map();
    this.usersNotInService = new Map();
    this.usersInGroup = new Map();
    this.usersNotInGroup = new Map();
    this.keyOwners = new Map();
    this.deletedUserIds = new Map();
    this.importReports = new Map();
    this.userChangeEvent = new CustomEvent("userChanged");
    this.userAddedEvent = new CustomEvent("userAdded");
    this.userDeletedEvent = new CustomEvent("userDeleted");

    this.accessesService = acessesService;
    this.notificationService = notificationService;

    this.getAPIUsers();

    this._initializeEnrollmentRegisteredHandler(
      eventsService,
      notificationService
    );
    this._initializeEnrollmentDeletedHandler(
      eventsService,
      notificationService
    );
    this._initializeUserAddedHandler(eventsService, notificationService);
    this._initializeEnrollmentEmailRegisteredHandler(
      eventsService,
      notificationService
    );
    this._initializeEnrollmentValidatedHandler(
      eventsService,
      notificationService
    );
    this._initializeUserUpdatedHandler(eventsService, notificationService);
    this._initializeUserDeletedHandler(eventsService, notificationService);
    this._initializeImportReportAddedHandler(
      eventsService,
      notificationService
    );
    this._initializeUserExportFinishedHandler(notificationService);
  }

  getUsers() {
    return Array.from(this.users.values()).sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
    );
  }

  async getTotalCompanyUserCount() {
    return await this.getUserSearchResultLength([]);
  }

  getServiceUsers() {
    return Array.from(this.serviceUsers.values());
  }

  getUsersNotInService() {
    return Array.from(this.usersNotInService.values());
  }

  transformProtectionFilter(filterArray) {
    const protectionFilter = filterArray.filter((filter) => {
      return filter.name === "Protection";
    })[0];

    const finalFilters = filterArray.filter((filter) => {
      return filter.name !== "Protection";
    });
    
    switch (protectionFilter?.selectedValue) {
      case USER_PROTECTION.FULL:
        finalFilters.push(
          new SearchTokenModel({
            name: "Verified",
            queryValue: "verified",
            selectedValue: "true",
            options: [],
          })
        );
        finalFilters.push(
          new SearchTokenModel({
            name: "Enabled",
            queryValue: "ironchip_enabled",
            selectedValue: "true",
            options: [],
          })
        );
        break;
      case USER_PROTECTION.MEDIUM:
        finalFilters.push(
          new SearchTokenModel({
            name: "Verified",
            queryValue: "verified",
            selectedValue: "false",
            options: [],
          })
        );
        finalFilters.push(
          new SearchTokenModel({
            name: "Enabled",
            queryValue: "ironchip_enabled",
            selectedValue: "true",
            options: [],
          })
        );
        break;
      case USER_PROTECTION.UNPROTECTED:
        finalFilters.push(
          new SearchTokenModel({
            name: "Invited",
            queryValue: "invited",
            selectedValue: "false",
            options: [],
          })
        );
        break;
      case USER_PROTECTION.DISABLED:
        finalFilters.push(
          new SearchTokenModel({
            name: "Invited",
            queryValue: "invited",
            selectedValue: "true",
            options: [],
          })
        );
        finalFilters.push(
          new SearchTokenModel({
            name: "Enabled",
            queryValue: "ironchip_enabled",
            selectedValue: "false",
            options: [],
          })
        );
        break;
      default:
        break;
    }
    return finalFilters;
  }

  async getUserSearchResultLength(filters = []) {
    return await ironchipsdk
      .GETMeCompanyUsersLength(this.transformProtectionFilter(filters))
      .catch((err) => {
        return 0;
      });
  }

  async getAPIUsers(
    skip,
    pageChange = false,
    filters = [],
    limit = USERS_PER_PAGE
  ) {
    if (pageChange && skip === currentSkip && skip !== 0) return;

    const finalFilters = this.transformProtectionFilter(filters);

    await ironchipsdk
      .GETMeCompanyUsers(limit, skip, finalFilters)
      .then((users) => {
        currentSkip = skip;
        let temporaryUsers = new Map();
        users.forEach((user) => {
          // FIXME should be set with an ID, but enrollment event doesnt have one
          temporaryUsers.set(
            user.email,
            new UserModel(
              user.id,
              user.name,
              user.surname,
              user.email,
              user.external_email,
              user.lang,
              user.immutable_id,
              user.position,
              user.roles,
              user.company_id,
              user.verified,
              user.ironchip_enabled,
              user.sam_account_name,
              user.user_principal_name,
              user.invited
            )
          );
        });
        this.users = temporaryUsers;
        window.dispatchEvent(this.userChangeEvent);
      })
      .catch((error) => {
        this.users = new Map();
        if (error.response.status === 404) {
          window.dispatchEvent(this.userChangeEvent);
        }
        console.error("No users found");
      });
  }

  getUser(userID) {
    let userEmail;
    for (const user of this.users.values()) {
      if (user.id === userID) {
        userEmail = user.email;
        break;
      }
    }
    return this.users.get(userEmail);
  }

  getServiceUser(userID) {
    let userEmail;
    for (const user of this.serviceUsers.values()) {
      if (user.id === userID) {
        userEmail = user.email;
        break;
      }
    }
    return this.serviceUsers.get(userEmail);
  }

  getAPIUser(id) {
    return ironchipsdk
      .GETMeCompanyUserByID(id)
      .then((resp) => {
        const user = new UserModel(
          resp.id,
          resp.name,
          resp.surname,
          resp.email,
          resp.external_email,
          resp.lang,
          resp.immutable_id,
          resp.position,
          resp.roles,
          resp.company_id,
          resp.verified,
          resp.ironchip_enabled,
          resp.sam_account_name,
          resp.user_principal_name
        );
        return user;
      })
      .catch(() => {
        throw err;
      });
  }

  async getServiceUsers(serviceID, limit = "", skip = "", filter = "") {
    let usersOfService = await ironchipsdk
      .GETMeCompanyServiceUsersByID(serviceID, limit, skip, filter)
      .then((resp) => {
        let temporaryUsers = new Map();
        resp.forEach((user) => {
          temporaryUsers.set(
            user.email,
            new UserModel(
              user.id,
              user.name,
              user.surname,
              user.email,
              user.external_email,
              user.lang,
              user.immutable_id,
              user.position,
              user.roles,
              user.company_id,
              user.verified,
              user.ironchip_enabled,
              user.sam_account_name,
              user.user_principal_name
            )
          );
        });
        this.serviceUsers = temporaryUsers;
      })
      .catch((err) => {
        this.serviceUsers.clear();
        if (err.response.status === 404) return [];
        throw err;
      });
    return usersOfService;
  }

  async fetchUsersNotInService(serviceID, limit = "", skip = "", filter = "") {
    let usersNotInService = await ironchipsdk
      .GETMeCompanyServiceUsersByID(serviceID, limit, skip, filter, false)
      .then((resp) => {
        let temporaryUsers = new Map();
        resp.forEach((user) => {
          temporaryUsers.set(
            user.email,
            new UserModel(
              user.id,
              user.name,
              user.surname,
              user.email,
              user.external_email,
              user.lang,
              user.immutable_id,
              user.position,
              user.roles,
              user.company_id,
              user.verified,
              user.ironchip_enabled,
              user.sam_account_name,
              user.user_principal_name
            )
          );
        });
        this.usersNotInService = temporaryUsers;
      })
      .catch((err) => {
        this.usersNotInService.clear();
        if (err.response.status === 404) return [];
        throw err;
      });
    return usersNotInService;
  }

  async fetchUsersInGroup(limit, skip, filters, groupId, included = true) {
    const finalFilters = this.transformProtectionFilter(filters);
    await ironchipsdk
      .GETMeCompanyUsers(limit, skip, finalFilters, groupId, included)
      .then((resp) => {
        let temporaryUsers = new Map();
        resp.forEach((user) => {
          temporaryUsers.set(
            user.email,
            new UserModel(
              user.id,
              user.name,
              user.surname,
              user.email,
              user.external_email,
              user.lang,
              user.immutable_id,
              user.position,
              user.roles,
              user.company_id,
              user.verified,
              user.ironchip_enabled,
              user.sam_account_name,
              user.user_principal_name
            )
          );
        });
        this.usersInGroup = temporaryUsers;
      })
      .catch((err) => {
        this.usersInGroup.clear();
        if (err.response.status === 404) return [];
        //throw err;
      });
    return Array.from(this.usersInGroup.values());
  }

  async fetchUsersInGroupCount(
    filters = [],
    groupId,
    included = true,
    enabled = false
  ) {
    let userCount = 0;
    const finalFilters = this.transformProtectionFilter(filters);
    await ironchipsdk
      .GETMeCompanyUsersLength(finalFilters, groupId, included, enabled)
      .then((response) => {
        userCount = response;
      })
      .catch((err) => {
        throw err;
      });
    return userCount;
  }

  async fetchAndGetFirstUserInGroup(groupId, included = true) {
    return await ironchipsdk
      .GETMeCompanyUsers(1, 0, [], groupId, included)
      .then((resp) => {
        return new UserModel(
          resp[0].id,
          resp[0].name,
          resp[0].surname,
          resp[0].email,
          resp[0].external_email,
          resp[0].lang,
          resp[0].immutable_id,
          resp[0].position,
          resp[0].roles,
          resp[0].company_id,
          resp[0].verified,
          resp[0].ironchip_enabled,
          resp[0].sam_account_name,
          resp[0].user_principal_name
        );
      })
      .catch((error) => {
        return new UserModel(
          "0000",
          EXAMPLE_USER_NAME,
          "User",
          "exampleuser@domain.com",
          "exampleuser-external@domain2.com",
          "en",
          "immutableID",
          "",
          [],
          "C0000",
          false,
          false,
          "samAccountName",
          "userPrincipalName"
        );
      });
  }

  getUserName(userID) {
    let userEmail;
    this.users.forEach((user) => {
      if (user.id === userID) {
        userEmail = user.email;
      }
    });
    return this.users.get(userEmail).name;
  }

  async addUser(user) {
    const userAdded = {
      email: user.email,
      name: user.name,
      surname: user.surname,
      lang: user.lang,
      ironchip_enabled: user.ironchipEnabled,
    };
    return await ironchipsdk
      .POSTMeCompanyUsers(userAdded)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async deleteUser(id) {
    return await ironchipsdk
      .DELETEMeCompanyUserByID(id)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  //TODO: UPDATE TO NEW ENDPOINT
  // POST BY USERID/DEVICE
  //(THIS IS THE RESEND INVITATION METHOD)
  async updateEnrollment(data) {
    return await ironchipsdk
      .PUTMeCompanyRegister(data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  exportUsers(filters) {
    return ironchipsdk
      .GETMeCompanyUserExport(this.transformProtectionFilter(filters))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        errorsService.onAPIError(error);
        throw error;
      });
  }

  async importUsers(file, groupName, enableIronchip, unassign, remove, notify) {
    return await ironchipsdk
      .POSTMeCompanyImport(
        file,
        groupName,
        enableIronchip,
        unassign,
        remove,
        notify
      )
      .then((resp) => {
        store.commit("setUsersImportID", resp.id);
        store.commit("isUsersImportActive", true);
        return resp;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getImportStatus(importID) {
    let requestStatus;
    requestStatus = setInterval(() => {
      if (
        !store.state.auth.importInProgress ||
        !store.state.auth.usersImportID
      ) {
        clearInterval(requestStatus);
        requestStatus = null;
        return;
      }
      ironchipsdk
        .GETMeCompanyImportsReportByID(importID)
        .then((resp) => {
          if (
            resp.status === IMPORT_COMPLETED ||
            resp.status === IMPORT_INCOMPLETED
          ) {
            store.commit("isUsersImportActive", false);
            store.commit("setUsersImportID", null);
            const importCompleted = new CustomEvent("importCompleted", {
              detail: resp,
            });
            window.dispatchEvent(importCompleted);
          }
          if (resp.status === IMPORT_IN_PROGRESS) {
            const importInProgress = new CustomEvent("importInProgress", {
              detail: resp,
            });
            window.dispatchEvent(importInProgress);
          }
        })
        .catch((err) => {
          store.commit("isUsersImportActive", false);
          store.commit("setUsersImportID", null);
          throw err;
        });
    }, 1000);
  }

  async fetchImportReports(limit = 0, skip = 0, dispatchEvent = true) {
    return ironchipsdk
      .GETMeCompanyImportsReports(limit, skip)
      .then((response) => {
        let temporaryReports = new Map();
        for (let report of response) {
          temporaryReports.set(report.id, report);
        }

        this.importReports = temporaryReports;
        if (dispatchEvent) window.dispatchEvent(SYNC_REPORTS_CHANGED_EVENT);
        return response;
      })
      .catch((error) => {
        if (error.response.status == "404") {
          console.error("No pending reports found");
        }
      });
  }

  getImportReports() {
    return Array.from(this.importReports.values());
  }

  async fetchImportReportsCount(filter) {
    return await ironchipsdk
      .GETMeCompanyImportReportsCount(filter)
      .catch((err) => {
        return 0;
      });
  }

  async resolveUserId(userIdToResolve) {
    if (this.keyOwners.size <= 0) {
      this.keyOwners = new Map(this.getUsers().map((user) => [user.id, user]));
    }

    let keyCreator = this.keyOwners.get(userIdToResolve);
    if (keyCreator) return keyCreator;

    let creatorWasDeleted = this.deletedUserIds.get(userIdToResolve);
    if (creatorWasDeleted) return new UserModel("0", "Deleted user", "");

    if (!keyCreator) {
      return await this.getAPIUser(userIdToResolve)
        .then((user) => {
          this.keyOwners.set(user.id, user);
          return user;
        })
        .catch((error) => {
          this.deletedUserIds.set(userIdToResolve, true);
          return new UserModel("0", "Deleted user", "");
        });
    }
  }

  _initializeEnrollmentRegisteredHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ENROLLMENT_REGISTERED,
      function (notification) {
        this._onEnrollmentAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeEnrollmentEmailRegisteredHandler(
    eventsService,
    notificationService
  ) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ENROLLMENT_EMAIL_REGISTERED,
      function (notification) {
        this._onEnrollmentEmailAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeEnrollmentValidatedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ENROLLMENT_VALIDATED,
      function (notification) {
        this._onEnrollmentValidated(notification);
      }.bind(this),
      null
    );
  }

  _initializeEnrollmentDeletedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.ENROLLMENT_DELETED,
      function (notification) {
        this._onEnrollmentDeleted(notification.data.email);
      }.bind(this),
      null
    );
  }

  _initializeUserAddedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.USER_ADDED,
      function (notification) {
        this._onUserAdded(notification.data.id);
      }.bind(this),
      null
    );
  }

  _initializeUserUpdatedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.USER_UPDATED,
      function (notification) {
        this._onUserDeleted(notification.data.id);
        this._onUserAdded(notification.data.id);
      }.bind(this),
      null
    );
  }

  _initializeUserDeletedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.USER_DELETED,
      function (notification) {
        this._onUserDeleted(notification.data.id);
      }.bind(this),
      null
    );
  }

  _initializeImportReportAddedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.IMPORT_REPORT_ADDED,
      function (notification) {
        this._onImportReportAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeUserExportFinishedHandler(notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.USER_EXPORT_FINISHED,
      function (notification) {
        this._onUserExportFinished(notification);
      }.bind(this),
      null
    );
  }

  async _onEnrollmentAdded(user) {
    if (store.state.auth.importInProgress || store.state.auth.usersImportID)
      return;
    if (this.users.size < USERS_PER_PAGE) {
      this.users.set(
        user.data.email,
        new UserModel(
          "",
          user.data.name,
          user.data.surname,
          user.data.email,
          user.data.external_email,
          user.data.lang,
          user.data.immutable_id,
          user.data.position,
          user.data.roles,
          user.company_id,
          user.data.consumed,
          user.data.ironchip_enabled,
          user.data.sam_account_name,
          user.data.user_principal_name
        )
      );

      let usersArray = this.getUsers();
      let unverifiedUsers = [];
      let verifiedUsers = [];
      usersArray.forEach((user) => {
        if (!user.verified) {
          unverifiedUsers.push(user);
          usersArray.splice();
        }
      });
      for (let i = 0; i < usersArray.length; i++) {
        if (!usersArray[i].verified) {
          unverifiedUsers.push(usersArray[i]);
        } else {
          verifiedUsers.push(usersArray[i]);
        }
      }
      unverifiedUsers.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
      );
      usersArray = [...verifiedUsers, ...unverifiedUsers];
      this.users = new Map(usersArray.map((user) => [user.email, user]));
    }
    window.dispatchEvent(this.userChangeEvent);
  }

  _onEnrollmentEmailAdded(notification) {
    const addedEmail = notification.Data.user.email;
    let updatedUser = this.users.get(addedEmail);
    if (updatedUser) {
      updatedUser.ironchipEnabled = true;
      this.users.set(updatedUser.email, updatedUser);
      window.dispatchEvent(this.userChangeEvent);
    }
  }

  async _onEnrollmentValidated(notification) {
    const validatedEmail = notification.data.email;
    let updatedUser = this.users.get(validatedEmail);
    if (updatedUser && validatedEmail) {
      updatedUser.verified = true;
      this.users.set(updatedUser.email, updatedUser);
      window.dispatchEvent(this.userChangeEvent);
    }
  }

  async _onEnrollmentDeleted(userEmail) {
    await this.getAPIUsers(currentSkip)
      .catch(() => {
        this.getAPIUsers();
      })
      .finally(() => {
        window.dispatchEvent(this.userChangeEvent);
      });
  }

  _onUserAdded(addedUserId) {
    this.deletedUserIds.delete(addedUserId);
    window.dispatchEvent(this.userAddedEvent);
  }

  _onUserDeleted(deletedUserId) {
    this.deletedUserIds.set(deletedUserId, true);
    this.keyOwners.delete(deletedUserId);
    window.dispatchEvent(this.userDeletedEvent);
  }

  _onImportReportAdded(importReport) {
    store.commit("setUsersImportID", importReport.data.id);
    store.commit("isUsersImportActive", true);
    const importStarted = new CustomEvent("importStarted");
    window.dispatchEvent(importStarted);
  }

  _onUserExportFinished(notification) {
    const exportData = notification.data;
    const exportFinished = new CustomEvent("exportCompleted", {
      detail: { exportData },
    });
    window.dispatchEvent(exportFinished);
  }
}
