<template>
  <div class="py-0 d-flex">
    <v-text-field
      v-model.trim="searchText"
      dense
      :autocomplete="false"
      autocapitalize="off"
      autocorrect="off"
      hide-details
      outlined
      :placeholder="searchPlaceholder"
      :prepend-inner-icon="$icons.Search"
      class="user-search-input"
      v-on:keyup.enter="
        () => {
          if (!autoSearch) requestSearch();
        }
      "
      style="margin-top: 0.5px" />

    <v-btn
      depressed
      class="ml-3 secondary-btn"
      small
      @click="requestSearch"
      text>
      <v-icon> {{ $icons.Search }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { debounce } from "@/utils/debouncer";
export default {
  props: {
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    autoSearch: Boolean,
  },
  data() {
    return {
      searchText: "",
      debouncedSearchText: "",
    };
  },
  methods: {
    requestSearch() {
      this.$emit("requestSearch", this.searchText);
    },
  },
  watch: {
    searchText: debounce(function (newValue) {
      if (this.autoSearch) this.debouncedSearchText = newValue;
    }, 500),
    debouncedSearchText: function (newValue) {
      this.$emit("requestSearch", newValue);
    },
  },
};
</script>
