const state = {
  token: null,
  state: null,
  user: null,
  userNotFound: false,
  noNetworkAccess: false,
  importInProgress: false,
  usersImportID: null,
};

const mutations = {
  login: (state, payload) => {
    state.token = payload;
  },
  setState: (state, payload) => {
    state.state = payload;
  },
  userNotFound: (state, payload) => {
    state.userNotFound = payload;
  },
  noNetworkAccess: (state, payload) => {
    state.noNetworkAccess = payload;
  },
  user: (state, payload) => {
    state.user = payload;
  },
  logout: (state, payload) => {
    state.token = null;
    state.user = null;
  },
  isUsersImportActive: (state, payload) => {
    state.importInProgress = payload;
  },
  setUsersImportID: (state, payload) => {
    state.usersImportID = payload;
  },
};

const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
