import { ironchipsdk } from "ironchip-lbauth-javascript-sdk/ironchip-module-stable";
import KeyModel, {
  DEVICE_OWNERSHIP_TYPE,
  SAFEZONE_TYPE,
} from "../models/keyModel";
import errorsService from "./errorsService";

let currentSkip = 0;

export const KEYS_PER_PAGE = 9;

let currentKeyPerPage = KEYS_PER_PAGE;

export default class KeysService {
  constructor(eventsService, notificationService) {
    this.keys = new Map();
    this.devices = new Map();
    this.sharedKeys = new Map();
    this.canReadKeys = false;
    this.keyChangeEvent = new CustomEvent("keyChanged");

    this.getAPIPersonalKeys();
    this.getAPIPersonalDevices();
    this.getAPISharedKeys(0);

    this._initializeKeyAddedHandler(eventsService, notificationService);
    this._initializeKeyUpdatedHandler(eventsService, notificationService);
    this._initializeKeyDeletedHandler(eventsService, notificationService);
  }

  setKeysPerPage(value) {
    currentKeyPerPage = value;
  }

  getKeysPerPage() {
    return currentKeyPerPage;
  }

  getKeys() {
    return Array.from(this.keys.values());
  }

  getDevices() {
    return Array.from(this.devices.values());
  }

  getSharedKeys() {
    return Array.from(this.sharedKeys.values());
  }

  async getKeySearchResultLength(shared = false, filter, type = "") {
    return await ironchipsdk
      .GETMeCompanyKeysLength(shared, filter, type)
      .catch(() => {
        return 0;
      });
  }

  async getAPIPersonalKeys(skip = "", pageChange = false, filter = "") {
    if (pageChange && skip === currentSkip && skip !== 0) return;
    currentSkip = skip;
    let limit = currentKeyPerPage;
    if (filter.length > 2) limit = "";
    await ironchipsdk
      .GETMeCompanyKeys(false, limit, skip, filter, SAFEZONE_TYPE)
      .then((personalKeys) => {
        let temporalKeys = new Map();
        personalKeys.forEach((key) => {
          temporalKeys.set(
            key.id,
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data
            )
          );
        });
        this.keys = temporalKeys;
      })
      .catch((error) => {
        this.keys = new Map();
        if ((error.response.status = "404")) {
          console.error("No private keys found");
          this.keys = new Map();
        }
      })
      .finally(() => {
        window.dispatchEvent(this.keyChangeEvent);
      });
  }

  async getAPIPersonalDevices(skip = "", pageChange = false, filter = "") {
    if (pageChange && skip === currentSkip && skip !== 0) return;
    currentSkip = skip;
    let limit = currentKeyPerPage;
    if (filter.length > 2) limit = "";
    await ironchipsdk
      .GETMeCompanyKeys(false, limit, skip, filter, DEVICE_OWNERSHIP_TYPE)
      .then((personalKeys) => {
        let temporalKeys = new Map();
        personalKeys.forEach((key) => {
          temporalKeys.set(
            key.id,
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data
            )
          );
        });
        this.devices = temporalKeys;
      })
      .catch((error) => {
        this.devices = new Map();
        if ((error.response.status = "404")) {
          console.error("No personal devices found");
        }
      })
      .finally(() => {
        window.dispatchEvent(this.keyChangeEvent);
      });
  }

  async getAPISharedKeys(skip = "", pageChange = false, filter = "") {
    if (pageChange && skip === currentSkip && skip !== 0) return;
    currentSkip = skip;
    let limit = currentKeyPerPage;
    if (filter.length > 2) limit = "";
    await ironchipsdk
      .GETMeCompanyKeys(true, limit, skip, filter, SAFEZONE_TYPE)
      .then((sharedKeys) => {
        let temporalKeys = new Map();
        sharedKeys.forEach((key) => {
          temporalKeys.set(
            key.id,
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data
            )
          );
        });
        this.sharedKeys = temporalKeys;
      })
      .catch((error) => {
        this.sharedKeys = new Map();
        if ((error.response.status = "404")) {
          console.error("No shared keys found");
        }
      })
      .finally(() => {
        window.dispatchEvent(this.keyChangeEvent);
      });
  }

  getKeyById(keyId) {
    return this.keys.get(keyId);
  }

  getKeyGroupsRelatedToKeyId(keyId) {
    return ironchipsdk.GETMeCompanyKeyGroupsByKeyId(keyId);
  }

  async fetchKeys(
    tagId = "",
    isPublic = "",
    filter = "",
    limit = "",
    skip = "",
    sort = ""
  ) {
    if (skip == 0) skip = "";
    await ironchipsdk
      .GETMeCompanyAllKeys(tagId, isPublic, filter, limit, skip, sort)
      .then((keys) => {
        let temporalKeys = new Map();
        keys.forEach((key) => {
          temporalKeys.set(
            key.id,
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data,
              key.assigned
            )
          );
        });
        this.keys = temporalKeys;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.keys = new Map();
          console.error("No keys found");
        }
      });
  }

  async getAllKeySearchResultLength(tagId, shared = false, filter = "") {
    return await ironchipsdk
      .GETMeCompanyAllKeysCount(tagId, shared, filter)
      .catch(() => {
        return 0;
      });
  }

  async getUserKeys(userID) {
    return await ironchipsdk
      .GETMeCompanyUserKeysByID(userID)
      .then((resp) => {
        let userKeys = [];
        resp.forEach((key) => {
          userKeys.push(
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data
            )
          );
        });
        return userKeys;
      })
      .catch((err) => {
        throw err;
      });
  }

  async deleteKey(userID, keyID) {
    return await ironchipsdk
      .DELETEMeCompanyUserKeyByID(userID, keyID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async updateKey(userID, keyID, key) {
    return await ironchipsdk
      .PATCHMeCompanyUserKeyByID(userID, keyID, key)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async addDOKey(userId) {
    return await ironchipsdk
      .POSTMeCompanyUserDOByID(userId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        errorsService.onAPIError(err);
        throw err;
      });
  }

  async _initializeKeys() {
    await ironchipsdk
      .GETMeCompanyKeys("")
      .then((keys) => {
        let temporalKeys = new Map();
        keys.forEach((key) => {
          temporalKeys.set(
            key.id,
            new KeyModel(
              key.id,
              key.type,
              key.shared,
              key.user_id,
              key.company_id,
              key.data
            )
          );
        });
        this.keys = temporalKeys;
        this.canReadKeys = true;
        window.dispatchEvent(this.keyChangeEvent);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.canReadKeys = false;
        } else {
          let serviceInitError = new CustomEvent("serviceInitError", {
            detail: KEYS_SERVICE_INITIALIZATION_ERROR,
          });
          window.dispatchEvent(serviceInitError);
        }
      });
  }

  _initializeKeyAddedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_ADDED,
      function (notification) {
        this._onKeyAdded(notification);
      }.bind(this),
      null
    );
  }

  _initializeKeyUpdatedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_UPDATED,
      function (notification) {
        this._onKeyUpdated(notification);
      }.bind(this),
      null
    );
  }

  _initializeKeyDeletedHandler(eventsService, notificationService) {
    notificationService.registerNotificationHandler(
      notificationService.notificationTypes.KEY_DELETED,
      function (notification) {
        this._onKeyDeleted(notification);
      }.bind(this),
      null
    );
  }

  async _onKeyAdded(key) {
    //
    await this.getAPIPersonalKeys(currentSkip).catch(() => {
      this.getAPIPersonalKeys(currentSkip - currentKeyPerPage);
    });
    await this.getAPISharedKeys(currentSkip).catch(() => {
      this.getAPISharedKeys(currentSkip - currentKeyPerPage);
    });

    window.dispatchEvent(this.keyChangeEvent);
  }

  async _onKeyUpdated(key) {
    //'key' is in reality an event
    let keyToUpdate = this.keys.get(key.data.id);
    if (keyToUpdate) {
      this.keyUpdated(keyToUpdate, key);
    } else {
      keyToUpdate = this.sharedKeys.get(key.data.id);
      if (keyToUpdate) this.keySharedUpdate(keyToUpdate, key);
    }

    window.dispatchEvent(this.keyChangeEvent);
  }

  async keyUpdated(keyToUpdate, key) {
    let updatedKeyName = key.data.name;
    let updatedKeyData = {};

    if (key.data.name === "") {
      updatedKeyName = keyToUpdate.data.name;
    }

    switch (keyToUpdate.type) {
      case DEVICE_OWNERSHIP_TYPE:
        updatedKeyData.name = updatedKeyName;
        updatedKeyData.platform = keyToUpdate.data.platform;
        break;
      case SAFEZONE_TYPE:
        updatedKeyData.name = updatedKeyName;
        updatedKeyData.type = keyToUpdate.data.type;
        break;
      default:
        throw new Error("Undefined key type");
    }

    if (key.data.shared) {
      this.sharedKeys.set(
        key.data.id,
        new KeyModel(
          key.data.id,
          keyToUpdate.type,
          key.data.shared,
          keyToUpdate.userId,
          key.company_id,
          updatedKeyData
        )
      );
    }

    await this.getAPIPersonalKeys(currentSkip).catch(() => {
      this.getAPIPersonalKeys(currentSkip - currentKeyPerPage);
    });
  }

  async keySharedUpdate(keyToUpdate, key) {
    let updatedKeyName = key.data.name;
    let updatedKeyData = {};

    if (key.data.name === "") {
      updatedKeyName = keyToUpdate.data.name;
    }

    switch (keyToUpdate.type) {
      case DEVICE_OWNERSHIP_TYPE:
        updatedKeyData.name = updatedKeyName;
        updatedKeyData.platform = keyToUpdate.data.platform;
        break;
      case SAFEZONE_TYPE:
        updatedKeyData.name = updatedKeyName;
        updatedKeyData.type = keyToUpdate.data.type;
        break;
      default:
        throw new Error("Undefined key type");
    }

    if (!key.data.shared) {
      this.keys.set(
        key.data.id,
        new KeyModel(
          key.data.id,
          keyToUpdate.type,
          key.data.shared,
          keyToUpdate.userId,
          key.company_id,
          updatedKeyData
        )
      );
    }

    await this.getAPISharedKeys().catch();
  }

  async _onKeyDeleted(key) {
    await this.getAPIPersonalKeys(currentSkip).catch(() => {
      this.getAPIPersonalKeys(currentSkip - currentKeyPerPage);
    });
    await this.getAPISharedKeys(currentSkip).catch(() => {
      this.getAPISharedKeys(currentSkip - currentKeyPerPage);
    });
    window.dispatchEvent(this.keyChangeEvent);
  }
}
