const FREQUENCY = {
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  YEARLY: "Yearly",
};
Object.freeze(FREQUENCY);
export const BILLINGFREQUENCY = FREQUENCY;

//COMPANYDETAILS
class BillingDetails {
  constructor(
    options = {
      company_cif: null,
      client_number: null,
      company_tax: null,
    }
  ) {
    this.VATNumber = options.company_cif || null;
    this.clientNumber = options.client_number;
    this.tax = options.company_tax;
  }
}

export default class BillingDetailsModel {
  constructor(
    options = {
      _id: null,
      company_id: null,
      company_details: null,
      company_name: null,
      billing_email: null,
      billing_frequency: null,
      discount: null,
      billing_address: null,
    }
  ) {
    this.id = options._id;
    this.companyId = options.company_id;
    this.billingDetails = new BillingDetails(options.company_details);
    this.companyName = options.company_name;
    this.billingEmail = options.billing_email || null;
    this.billingInterval = options.billing_frequency || FREQUENCY.YEARLY;
    this.discount = options.discount;
    this.billingAddress = options.billing_address || null;
  }

  toJSON() {
    return {
      _id: this.id,
      company_id: this.companyId,
      company_details: {
        company_cif: this.billingDetails.VATNumber,
        client_number: this.billingDetails.clientNumber,
        company_tax: this.billingDetails.tax,
      },
      company_name: this.companyName,
      billing_email: this.billingEmail,
      billing_frequency: this.billingInterval,
      discount: this.discount,
      billing_address: this.billingAddress,
    };
  }

  equals(objectB) {
    if (this.billingDetails.VATNumber !== objectB.billingDetails.VATNumber)
      return false;
    if (this.billingEmail !== objectB.billingEmail) return false;
    if (this.billingAddress !== objectB.billingAddress) return false;
    if (this.billingInterval !== objectB.billingInterval) return false;
    return true;
  }
}
