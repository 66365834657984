<template>
  <div class="d-flex token">
    <div class="tag">{{ token.name }}</div>
    <div class="tag">=</div>
    <v-select
      v-if="token.options.length > 0"
      ref="tokenOptions"
      :items="token.options"
      v-model="token.selectedValue"
      label="Select..."
      dense
      solo
      chips
      class="optionSelector">
    </v-select>
    <input
      v-else
      ref="input"
      focused
      :id="`${token.name}ValueInput`"
      type="text"
      v-model.trim="token.selectedValue"
      :autocomplete="false"
      autocapitalize="off"
      autocorrect="off"
      hide-details
      outlined
      @keydown.enter="requestSearch"
      :placeholder="'Enter value'"
      class="tag-input__text resizable-input mr-1" />
    <v-icon size="20" color="white" @click="removeToken">
      {{ $icons.Close }}
    </v-icon>
  </div>
</template>
<script>
import { debounce } from "@/utils/debouncer";

export default {
  data() {
    return {
      debouncedValue: null,
    };
  },
  props: {
    token: {
      type: Object,
      default: () => {
        return { name: "", options: [] };
      },
    },
  },
  methods: {
    removeToken() {
      this.$emit("removeToken", this.token);
    },
    requestSearch() {
      this.$emit("requestSearch");
    },
  },
  watch: {
    "token.selectedValue": debounce(function (newValue) {
      this.debouncedValue = newValue;
    }, 500),
    debouncedValue: function (newValue) {
      this.requestSearch();
    },
  },
  mounted() {
    const inputField = document.querySelector(`#${this.token.name}ValueInput`);
    inputField.style.width = "75px";
    inputField.addEventListener("input", () => {
      const newWidth = inputField.value.length * 7.2 + 5;
      inputField.style.width = newWidth >= 75 ? newWidth + 3 + "px" : "75px";
    });
    this.$refs.input.focus();
  },
};
</script>
<style scoped>
#valueInput {
  width: 75px;
}

.token {
  background-color: rgb(213, 213, 213);
  border-radius: 5px;
  padding: 0px 2px;
  margin-right: 4px;
  margin-top: 1px;
  margin-bottom: 1px;
  width: min-content;
}

.tag {
  background-color: rgb(162, 162, 162);
  color: white;
  margin: 3px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.optionSelector {
  max-height: 20px !important;
  padding: 0px !important;
  margin-left: 2px;
  min-width: 80px;
}

.optionSelector >>> div[role="button"] {
  padding: 0px 5px !important;
  padding-left: 0px !important;
  max-height: 20px !important;
  margin-top: -7px;
  background-color: transparent !important;
  box-shadow: none !important;
}

.optionSelector >>> *,
.optionSelector >>> *:focus {
  border-style: none;
}

.optionSelector >>> .v-label--active {
  display: none;
}

.optionSelector >>> .v-chip--select {
  background-color: rgb(162, 162, 162) !important;
  color: white;
  border-radius: 10px;
  max-height: 20px !important;
  margin-top: 2px;
  width: fit-content;
}

.optionSelector >>> .v-chip--select ~ input[type="text"] {
  width: 0px;
}

.resizable-input {
  width: auto;
  min-width: 60px !important;
}
</style>
