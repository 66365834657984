<template>
  <div>
    <div class="d-flex align-items-center">
      <div
        class="searchFilter d-flex flex-grow-1"
        :style="`--borderColor:${
          hasFocus ? 'var(--v-primary-base)' : 'rgb(175, 175, 175)'
        };`">
        <!-- ICON -->
        <v-icon class="pl-2" :color="hasFocus ? 'var(--v-primary-base)' : ''">
          {{ $icons.Search }}
        </v-icon>
        <SearchToken
          v-for="token in selectedSearchTokens"
          :key="token.name"
          :token="token"
          @removeToken="removeSearchToken"
          @requestSearch="requestSearch" />
        <input
          :id="tableId"
          type="text"
          v-model.trim="searchText"
          autocomplete="off"
          autocapitalize="off"
          autocorrect="off"
          hide-details
          outlined
          :placeholder="searchPlaceholder"
          class="tag-input__text mr-1 ml-1"
          v-on:keyup.enter="
            () => {
              if (!autoSearch) requestSearch();
            }
          "
          v-on:focus="focus"
          v-on:blur="blur"
          list="filter-suggestions" />
        <!-- CAREFUL WITH THIS ID - COULD LEAD TO CONFLICTS -->
        <datalist id="filter-suggestions">
          <option
            v-for="token in remainingSearchTokens"
            :key="token.name"
            :value="token.name"></option>
        </datalist>
      </div>
      <div class="d-flex ml-2 align-items-center">
        <!-- BUTTONS -->
        <v-btn
          v-if="resultDownload"
          depressed
          class="secondary-btn mr-2 mt-auto mb-auto"
          small
          @click="requestDownload"
          text>
          <v-icon> {{ $icons.Download }} </v-icon>
        </v-btn>
        <v-btn
          depressed
          class="secondary-btn mt-auto mb-auto"
          small
          @click="requestSearch"
          text>
          <v-icon> {{ $icons.Search }} </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SearchToken from "@/components/common/table/ISearchToken";
import SearchTokenModel from "@/models/searchTokenModel.js";
export default {
  components: { SearchToken },
  props: {
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    autoSearch: Boolean,
    resultDownload: Boolean,
    searchTokens: {
      type: Array,
      default: [],
    },
    tableId: {
      type: String,
      default: "searchInput",
    },
  },
  data() {
    return {
      searchText: "",
      debouncedSearchText: "",
      selectedSearchTokens: [],
      hasFocus: false,
    };
  },
  methods: {
    requestSearch() {
      this.$emit("requestSearch", this.selectedSearchTokens);
    },
    requestDownload() {
      this.$emit("requestDownload", this.selectedSearchTokens);
    },
    focus() {
      this.hasFocus = true;
    },
    blur() {
      this.hasFocus = false;
    },
    addSearchToken(name) {
      let selectedToken = this.searchTokens.filter(
        (token) => token.name === name
      )[0];
      this.selectedSearchTokens.push(
        new SearchTokenModel({
          name: name,
          queryValue: selectedToken.queryValue,
          options: selectedToken.options,
        })
      );
    },
    removeSearchToken(tokenToRemove) {
      this.selectedSearchTokens = this.selectedSearchTokens.filter(
        (token) => token.name !== tokenToRemove.name
      );
    },
    setInputFieldListeners() {
      let searchInput = document.getElementById(this.tableId);
      let eventSource = null;
      let value = "";

      searchInput.addEventListener("keydown", (event) => {
        eventSource = event.key ? "input" : "list";
      });

      searchInput.addEventListener("input", (event) => {
        value = event.target.value;
        this.searchText = "";
        if (
          eventSource === "list" ||
          event.inputType === "insertReplacementText"
        ) {
          this.searchText = "";
          this.addSearchToken(value);
        }
      });
    },
  },
  computed: {
    remainingSearchTokens() {
      const selectedTokenNames = this.selectedSearchTokens.map(
        (token) => token.name
      );
      return this.searchTokens.filter(
        (token) => !selectedTokenNames.includes(token.name)
      );
    },
  },
  watch: {},
  mounted() {
    this.setInputFieldListeners();
  },
};
</script>
<style scoped>
.searchFilter {
  min-height: 34px;
  color: rgb(140, 140, 140);
  display: flex;
  flex-wrap: wrap;
  border-width: 1px;
  border-color: var(--borderColor) !important;
  border-style: solid;
  border-radius: 5px;
  margin-top: calc(2px - var(--borderWidth));
  margin-bottom: calc(2px - var(--borderWidth));
  padding-left: calc(2px - var(--borderWidth));
  padding-right: calc(2px - var(--borderWidth));
  padding-top: 1px;
  padding-bottom: 1px;
}
.searchFilter:hover {
  border-color: rgb(98, 98, 98);
  /* height: 30px; */
}

.searchFilter:focus {
  height: 30px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 20px;
  min-height: 26px;
  background: none;
  flex-grow: 1;
}
</style>
