export const AUTHORIZATION_CORRECT = "AuthorizationCorrect",
  AUTHORIZATION_WRONG = "AuthorizationWrong",
  AUTHORIZATION_INCOMPLETE = "AuthorizationIncompleted";

export class TimelineModel {
  constructor(timelineModel) {
    this.timelineModel = timelineModel;
  }

  getTimelineModel() {
    return this.timelineModel;
  }

  getTimelineProvidedKeys() {
    return Array.from(this.timelineModel.providedKeys.values()).sort(
      (key1, key2) => {
        return key1.time - key2.time;
      }
    );
  }

  getAuthorizationStatus() {
    if (this.timelineModel.consumed) {
      return AUTHORIZATION_CORRECT;
    } else if (
      this.timelineModel.expirationTime <=
      Date.now() * 1000000
    ) {
      return AUTHORIZATION_WRONG;
    } else {
      return AUTHORIZATION_INCOMPLETE;
    }
  }
}